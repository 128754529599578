<template>
  <abbr title="Obrigatório" class="text-danger">*</abbr>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
