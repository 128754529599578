<template>
  <i :class="`uicon ic_${icon} ${styleName}`"></i>
</template>

<script>
export default {
  props: ["icon", "styleName"],
  data() {
    return {};
  },
  created() {},
  methods: {},
  mounted() {},
  computed: {},
};
</script>
