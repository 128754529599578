require("./bootstrap");
import { createApp } from "vue";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

import AdminLoginComponent from "./components/Admin/Auth/AdminLoginComponenet.vue";
import UpdatePasswordComponent from "./components/Admin/UpdatePasswordComponent.vue";
import CategoryComponent from "./components/Admin/CategoryComponent.vue";
import PaymentMethodComponent from "./components/Admin/PaymentMethodComponent.vue";
import ItemComponent from "./components/Admin/ItemComponent.vue";
import BannerComponent from "./components/Admin/BannerComponent.vue";
import AreaComponent from "./components/Admin/AreaComponent.vue";
import CouponComponent from "./components/Admin/CouponComponent.vue";
import StoreStatusComponent from "./components/Admin/StoreStatusComponent.vue";
import NotificationComponent from "./components/Admin/NotificationComponent.vue";
import SettingsComponent from "./components/Admin/SettingsComponent.vue";
import ComposeMessageComponent from "./components/Admin/ComposeMessageComponent.vue";
import HomeComponent from "./components/HomeComponent.vue";
import CartButtonComponent from "./components/CartButtonComponent.vue";
import CartBottomButtonComponent from "./components/CartBottomButtonComponent.vue";
import CartComponent from "./components/CartComponent.vue";
import BackToTopComponent from "./components/Shared/BackToTopComponent.vue";
import ContactFormComponent from "./components/ContactFormComponent.vue";
import SubmitOrderComponent from "./components/SubmitOrderComponent.vue";
import ItemCartComponent from "./components/ItemCartComponent.vue";
import PrimeVue from "primevue/config";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Dropdown from "primevue/dropdown";
import Toaster from "@meforma/vue-toaster";
import store from "./store/store";
import CKEditor from "@ckeditor/ckeditor5-vue";
import IconComponent from "./components/Shared/IconComponent.vue";
import DiscountBadgeComponent from "./components/Shared/DiscountBadgeComponent.vue";
import PropertyBadgeComponent from "./components/Shared/PropertyBadgeComponent.vue";
import PlusButtonComponent from "./components/Shared/PlusButtonComponent.vue";
import MinusButtonComponent from "./components/Shared/MinusButtonComponent.vue";
import RequiredComponent from "./components/Shared/RequiredComponent.vue";

const app = createApp({
    components: {
        AdminLoginComponent,
        UpdatePasswordComponent,
        CategoryComponent,
        ItemComponent,
        StoreStatusComponent,
        PaymentMethodComponent,
        CouponComponent,
        NotificationComponent,
        AreaComponent,
        BannerComponent,
        SettingsComponent,
        ComposeMessageComponent,

        HomeComponent,
        CartComponent,
        CartButtonComponent,
        ContactFormComponent,
        SubmitOrderComponent,
        ItemCartComponent,
        CartBottomButtonComponent,

        BackToTopComponent,
        IconComponent,
        PropertyBadgeComponent,
        PlusButtonComponent,
        MinusButtonComponent,
        DiscountBadgeComponent,
        RequiredComponent,
    },
});

Sentry.init({
    app: app,
    dsn: "https://4fb71d37ee35493b8b8b68955b18fae7@o512093.ingest.sentry.io/5793803",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
});

app.use(store);
app.use(CKEditor);
app.use(PrimeVue);
app.use(Toaster, {
    // One of the options
    position: "bottom",
    duration: 2000,
});
app.component("DataTable", DataTable);
app.component("Column", Column);
app.component("Dropdown", Dropdown);
app.component("Icon", IconComponent);
app.component("DiscountBadge", DiscountBadgeComponent);
app.component("v-required", RequiredComponent);
app.mount("#app");
