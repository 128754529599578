<template>
  <div class="text-center fw-bolder text-dark h5 mb-5">
    <span> {{ price }}</span>
    <s class="ms-2" v-if="item.has_discount">{{ item.view_original_price }}</s>
    <DiscountBadge v-if="item.has_discount"></DiscountBadge>
  </div>

  <div class="row">
    <div class="col-md-6 mb-3">
      <div class="d-flex justify-content-center align-items-center">
        <div>
          <button
            type="button"
            class="btn btn-sm btn-primary"
            @click="decrement()"
          >
            <i class="uicon ic_rr_minus"></i>
          </button>
        </div>
        <div class="h4 px-5 text-center m-0 fw-bold">{{ quantity }}</div>
        <div>
          <button
            type="button"
            class="btn btn-sm btn-primary"
            @click="increment()"
          >
            <i class="uicon ic_rr_plus"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-6 mb-3">
      <button
        class="btn btn-danger w-100"
        type="button"
        v-on:click="addToCart()"
      >
        Adicionar
      </button>
    </div>
  </div>
</template>

<script>
import { Cart } from "@/services/cart";
import { blr_money_format } from "@/services/utils";
export default {
  props: ["item"],
  data() {
    return {
      quantity: 1,
    };
  },
  created() {},
  methods: {
    addToCart() {
      Cart.add(this.item, this.quantity);
      this.$store.state.cartTotal = Cart.total();
      this.$toast.show("Item Adicionado Ao Carrinho");
    },
    increment() {
      if (this.quantity > 100) return;
      this.quantity++;
    },

    decrement() {
      if (this.quantity < 2) return;
      this.quantity--;
    },
  },
  mounted() {},
  computed: {
    price() {
      return blr_money_format(
        parseFloat(this.item.base_price) * parseInt(this.quantity)
      );
    },
  },
};
</script>