<template>
  <div class="d-flex mb-3 justify-content-center">
    <div class="h3">Métodos de Pagamento</div>
    <div class="ms-auto">
      <button
        class="btn btn-primary px-4 ms-2"
        data-bs-toggle="modal"
        data-bs-target="#methodModal"
      >
        <i class="uicon ic_rr_plus"></i> Adicionar Método
      </button>
    </div>
  </div>

  <div class="card shadow-sm">
    <div class="card-body">
      <div class="position-relative mb-3">
        <input
          type="text"
          class="form-control w-auto"
          name="search"
          v-model="search"
          id="search"
          autocomplete="off"
          placeholder="Procurar..."
          style="padding-left: 2rem !important"
        />
        <div class="position-absolute top-50 start-0 translate-middle-y p-2">
          <i class="uicon ic_rr_search text-muted"></i>
        </div>
      </div>

      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
            <tr>
              <th class="text-muted small fw-normal">Método</th>
              <th class="text-muted small fw-normal">Tipo</th>
              <th class="text-muted small fw-normal">Status</th>
              <th class="text-muted small fw-normal"></th>
            </tr>
          </thead>
          <tbody class="border-top-0">
            <tr v-for="method in methodList" :key="method.id">
              <td class="align-middle">{{ method.name }}</td>
              <td class="align-middle">{{ method.type }}</td>
              <td class="align-middle">
                <div class="form-check form-switch">
                  <input
                    class="form-check-input cursor-pointer"
                    type="checkbox"
                    id="flexSwitchCheckChecked"
                    :checked="method.active"
                    v-on:change="updateStatus(method.id)"
                  />
                  <label class="form-check-label" for="flexSwitchCheckChecked">
                    {{ method.status }}
                  </label>
                </div>
              </td>
              <td class="align-middle">
                <button
                  class="btn btn-info btn-xs me-2"
                  data-bs-toggle="modal"
                  data-bs-target="#editMethodModal"
                  v-on:click="openEditModal(method)"
                >
                  Editar
                </button>

                <button
                  class="btn btn-danger btn-xs"
                  v-on:click="deleteMethod(method.id)"
                >
                  Excluir
                </button>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="text-center" v-if="methodList.length == 0">
          <h6>Sem dados disponíveis na tabela</h6>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Create -->
  <div
    class="modal fade"
    id="methodModal"
    tabindex="-1"
    aria-labelledby="methodModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content rounded-3">
        <div class="modal-header border-0">
          <h5 class="modal-title" id="methodModalLabel">Adicionar Método</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            v-on:click="restorFormControl"
          ></button>
        </div>
        <form @submit.prevent="storeMethod">
          <div class="modal-body">
            <div class="mb-3">
              <label for="name" class="form-label fw-bold">Método</label>
              <input
                type="text"
                class="form-control"
                v-model="data.name"
                id="name"
                :class="{ 'is-invalid': errors.hasOwnProperty('name') }"
                name="name"
              />
              <div
                class="invalid-feedback"
                v-if="errors.hasOwnProperty('name')"
              >
                {{ errors.name[0] }}
              </div>
            </div>
            <div class="mb-3">
              <label for="delivery" class="form-label fw-bold">Tipo*</label>
              <select
                id="delivery"
                v-model="data.delivery"
                class="form-select rounded-main cursor-pointer"
                :class="{ 'is-invalid': errors.hasOwnProperty('delivery') }"
                aria-label="Tipo*"
              >
                <option v-bind:value="true" selected>Para Entrega</option>
                <option v-bind:value="false">Para Retirada</option>
              </select>
              <div
                class="invalid-feedback"
                v-if="errors.hasOwnProperty('delivery')"
              >
                {{ errors.delivery[0] }}
              </div>
            </div>
          </div>
          <div class="modal-footer border-0">
            <button
              type="submit"
              class="btn btn-primary px-4"
              :disabled="loading"
            >
              Salve
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- Modal Update -->
  <div
    class="modal fade"
    id="editMethodModal"
    tabindex="-1"
    aria-labelledby="editMethodModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content rounded-3">
        <div class="modal-header border-0">
          <h5 class="modal-title" id="editMethodModalLabel">
            Editar Método
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            v-on:click="restorFormControl"
          ></button>
        </div>
        <form @submit.prevent="updateMethod(editData.id)">
          <div class="modal-body">
            <div class="mb-3">
              <label for="name-edit" class="form-label fw-bold">Método</label>
              <input
                type="text"
                class="form-control"
                v-model="editData.name"
                id="name-edit"
                :class="{ 'is-invalid': errors.hasOwnProperty('name') }"
              />
              <div
                class="invalid-feedback"
                v-if="errors.hasOwnProperty('name')"
              >
                {{ errors.name[0] }}
              </div>
            </div>
            <div class="mb-3">
              <label for="delivery" class="form-label fw-bold">Tipo*</label>
              <select
                id="delivery"
                v-model="editData.delivery"
                class="form-select rounded-main cursor-pointer"
                :class="{ 'is-invalid': errors.hasOwnProperty('delivery') }"
                aria-label="Tipo*"
              >
                <option v-bind:value="true">Para Entrega</option>
                <option v-bind:value="false">Para Retirada</option>
              </select>
              <div
                class="invalid-feedback"
                v-if="errors.hasOwnProperty('delivery')"
              >
                {{ errors.delivery[0] }}
              </div>
            </div>
          </div>
          <div class="modal-footer border-0">
            <button
              type="submit"
              class="btn btn-primary px-4"
              :disabled="loading"
            >
              Salve
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      methods: [],
      pageOfItems: [],
      search: "",
      data: {
        name: "",
        delivery: "",
      },

      editData: {
        id: "",
        name: "",
        delivery: "",
        _method: "PUT",
      },
      errors: {},
      loading: false,
    };
  },
  mounted() {},
  methods: {
    fetchMethods() {
      topbar.show();
      axios
        .get("/admin/payment_methods/all")
        .then((response) => {
          this.methods = response.data.data;
        })
        .catch(({ response }) => {
          Swal.fire(`Error ${response.status}`, response.statusText, "error");
        })
        .then(() => {
          topbar.hide();
        });
    },
    refreshTable() {
      this.fetchMethods();
    },

    deleteMethod(id) {
      Swal.fire({
        title: "Você tem certeza?",
        text: "Você não poderá reverter isso!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Cancelar",
        focusCancel: true,
        showClass: {
          popup: "",
          icon: "",
        },
        hideClass: {
          popup: "",
        },
      }).then((result) => {
        if (result.value) {
          topbar.show();
          axios
            .delete(`/admin/payment_methods/${id}`)
            .then((response) => {
              if (response.status == 200) {
                this.fetchMethods();
                this.$toast.success("Método Excluída");
              }
            })
            .catch(({ response }) => {
              Swal.fire(
                `Error ${response.status}`,
                response.statusText,
                "error"
              );
            })
            .then(() => {
              topbar.hide();
            });
        }
      });
    },
    storeMethod() {
      this.errors = {};
      topbar.show();
      this.loading = true;

      axios
        .post("/admin/payment_methods", this.data)
        .then((response) => {
          this.fetchMethods();
          this.data.name = "";
          this.$toast.success("Método Adicionada");
        })
        .catch(({ response }) => {
          if (response.status === 422 || response.status === 429) {
            this.errors = response.data.errors;
          } else {
            Swal.fire(`Error ${response.status}`, response.statusText, "error");
          }
        })
        .then(() => {
          topbar.hide();
          this.loading = false;
        });
    },

    updateMethod(id) {
      this.errors = {};
      topbar.show();
      this.loading = true;

      axios
        .post("/admin/payment_methods/" + id, this.editData)
        .then((response) => {
          this.fetchMethods();
          this.$toast.success("Método atualizada");
        })
        .catch(({ response }) => {
          if (response.status === 422 || response.status === 429) {
            this.errors = response.data.errors;
          } else {
            Swal.fire(`Error ${response.status}`, response.statusText, "error");
          }
        })
        .then(() => {
          topbar.hide();
          this.loading = false;
        });
    },
    openEditModal(method) {
      this.editData.id = method.id;
      this.editData.name = method.name;
      this.editData.delivery = method.is_delivery;
    },

    updateStatus(id) {
      topbar.show();
      axios
        .put("/admin/payment_methods/status/" + id)
        .then((response) => {
          this.fetchMethods();
          this.$toast.success("Método atualizada");
        })
        .catch(({ response }) => {
          Swal.fire(`Error ${response.status}`, response.statusText, "error");
        })
        .then(() => {
          topbar.hide();
        });
    },
    restorFormControl() {
      this.errors = {};
    },
  },

  created: function () {
    this.fetchMethods();
  },
  computed: {
    methodList() {
      const search = this.search.toLowerCase();
      if (!search) return this.methods;
      return this.methods.filter((method) => {
        return method.name.toLowerCase().includes(search);
      });
    },
  },
};
</script>