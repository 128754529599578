<template>
  <div class="row mt-3 mb-3">
    <div class="col-lg-9 col-md-12">
      <div class="row">
        <div class="col-md-12">
          <div class="mb-3">
            <label for="name" class="form-label">Nome<v-required /></label>
            <input
              type="text"
              id="name"
              v-model="data.name"
              class="form-control"
              :class="{ 'is-invalid': errors.hasOwnProperty('name') }"
              autocomplete="name"
            />
            <div class="invalid-feedback" v-if="errors.hasOwnProperty('name')">
              {{ errors.name[0] }}
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3">
            <label for="phone" class="form-label"
              >Número de telefone<v-required
            /></label>
            <input
              type="tel"
              name="phone"
              v-model="data.phone"
              class="form-control input-phone"
              :class="{ 'is-invalid': errors.hasOwnProperty('phone') }"
              autocomplete="tel"
            />
            <div class="invalid-feedback" v-if="errors.hasOwnProperty('phone')">
              {{ errors.phone[0] }}
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3">
            <label for="email" class="form-label">
              E-mail <small class="fw-normal text-muted">(opcional) </small>
            </label>
            <input
              type="text"
              id="email"
              v-model="data.email"
              class="form-control"
              :class="{ 'is-invalid': errors.hasOwnProperty('email') }"
              autocomplete="email"
            />
            <div class="invalid-feedback" v-if="errors.hasOwnProperty('email')">
              {{ errors.email[0] }}
            </div>
            <div
              id="emailHelp"
              class="form-text small"
              v-if="!errors.hasOwnProperty('email')"
            >
              Você receberá os detalhes do pedido em seu e-mail
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="mb-3">
            <label for="delivery" class="form-label">
              Tipo de pedido<v-required />
            </label>
            <select
              id="delivery"
              v-model="data.delivery"
              class="form-select cursor-pointer"
              :class="{ 'is-invalid': errors.hasOwnProperty('delivery') }"
              @change="selectChange"
            >
              <option v-bind:value="true" selected>Entrega</option>
              <option v-bind:value="false">Retirada</option>
            </select>
            <div class="text-danger" v-if="errors.hasOwnProperty('delivery')">
              {{ errors.delivery[0] }}
            </div>
          </div>
        </div>
        <div class="col-md-12" :class="{ 'd-none': !isDelivery }">
          <div class="card shadow-sm mb-3 rounded-main">
            <div class="card-body">
              <div class="card-title fs-5 fw-bold">Endereço de entrega</div>

              <div class="row">
                <div class="col-md-12">
                  <div class="mb-3">
                    <label for="area" class="form-label">
                      Área<v-required />
                    </label>
                    <Dropdown
                      v-model="data.area"
                      :options="areas"
                      optionLabel="name"
                      optionValue="id"
                      placeholder="Escolha Sua Área*"
                      :filter="true"
                      filterPlaceholder="Procurar..."
                      emptyFilterMessage="Nenhum resultado encontrado"
                      emptyMessage="Nenhum resultado encontrado"
                      :loading="loadingAreas"
                    />

                    <div
                      class="text-danger"
                      v-if="errors.hasOwnProperty('area')"
                    >
                      {{ errors.area[0] }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="street" class="form-label">
                      Rua<v-required />
                    </label>
                    <input
                      type="text"
                      id="street"
                      v-model="data.street"
                      class="form-control"
                      :class="{ 'is-invalid': errors.hasOwnProperty('street') }"
                      autocomplete="street"
                    />
                    <div
                      class="invalid-feedback"
                      v-if="errors.hasOwnProperty('street')"
                    >
                      {{ errors.street[0] }}
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="street_number" class="form-label">
                      Número<v-required />
                    </label>
                    <input
                      type="text"
                      id="street_number"
                      v-model="data.street_number"
                      class="form-control"
                      :class="{
                        'is-invalid': errors.hasOwnProperty('street_number'),
                      }"
                      autocomplete="street_number"
                    />
                    <div
                      class="invalid-feedback"
                      v-if="errors.hasOwnProperty('street_number')"
                    >
                      {{ errors.street_number[0] }}
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="complement" class="form-label">
                      Complemento<v-required />
                    </label>
                    <input
                      type="text"
                      id="complement"
                      v-model="data.complement"
                      class="form-control"
                      :class="{
                        'is-invalid': errors.hasOwnProperty('complement'),
                      }"
                      autocomplete="complement"
                    />
                    <div
                      class="invalid-feedback"
                      v-if="errors.hasOwnProperty('complement')"
                    >
                      {{ errors.complement[0] }}
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="neighborhood" class="form-label">
                      Bairro<v-required />
                    </label>
                    <input
                      type="text"
                      id="neighborhood"
                      v-model="data.neighborhood"
                      class="form-control"
                      :class="{
                        'is-invalid': errors.hasOwnProperty('neighborhood'),
                      }"
                      autocomplete="neighborhood"
                    />
                    <div
                      class="invalid-feedback"
                      v-if="errors.hasOwnProperty('neighborhood')"
                    >
                      {{ errors.neighborhood[0] }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <div class="mb-3">
            <label for="payment_method" class="form-label">
              Método De Pagamento<v-required />
            </label>
            <Dropdown
              v-model="data.payment_method"
              :options="paymentMethodsList"
              optionLabel="name"
              optionValue="id"
              placeholder="Método De Pagamento*"
              emptyFilterMessage="Nenhum resultado encontrado"
              emptyMessage="Nenhum resultado encontrado"
              :loading="loadingPaymentMethods"
            />
            <div
              class="invalid-feedback"
              v-if="errors.hasOwnProperty('payment_method')"
            >
              {{ errors.payment_method[0] }}
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="mb-3">
            <label for="payment_method" class="form-label">
              Comentário ao pedido
            </label>
            <textarea
              id="comment"
              v-model="data.comment"
              class="form-control"
              :class="{ 'is-invalid': errors.hasOwnProperty('comment') }"
              autocomplete="comment"
              rows="4"
            ></textarea>
            <div
              class="invalid-feedback"
              v-if="errors.hasOwnProperty('comment')"
            >
              {{ errors.comment[0] }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-12">
      <ul class="list-group list-group-flush">
        <li class="list-group-item">
          <span class="text-muted">Subltotal:</span>
          <div class="text-muted">
            {{ showSubtotal }}
          </div>
        </li>

        <li class="list-group-item" v-if="couponDiscountPrice > 0">
          <span class="text-muted">Desconto:</span>
          <div class="text-muted">
            {{ showDiscount }}
          </div>
        </li>

        <template v-if="isDelivery">
          <li class="list-group-item">
            <span class="text-muted">Taxa de entrega:</span>
            <div class="text-muted">
              {{ showDeliveryFee }}
            </div>
          </li>
          <li class="list-group-item">
            <span class="text-muted">Tempo de entrega:</span>
            <div class="text-muted">{{ deliveryTime }}</div>
          </li>
        </template>

        <li class="list-group-item">
          <span class="fw-bold">Total:</span>
          <div class="fw-bold">
            {{ showTotal }}
          </div>
        </li>
        <template v-if="couponAdded">
          <li class="list-group-item">
            <span class="text-muted">Cupom:</span>
            <div class="text-muted">{{ data.coupon_code }}</div>
          </li>
          <li class="list-group-item">
            {{ coupon.des }}
          </li>
        </template>
      </ul>

      <button
        v-if="!couponAdded"
        class="btn btn-link text-danger text-decoration-none w-100 mb-3"
        type="button"
        id="coupon-add-btn"
        data-bs-toggle="modal"
        data-bs-target="#CouponModal"
        :disabled="couponAdded"
      >
        Adicionar Cupom
      </button>
    </div>
    <div class="col-lg-9 col-sm-12 text-center">
      <button
        type="submit"
        class="btn btn-primary btn-lg px-5"
        :disabled="loading"
        @click="storeOrder"
      >
        Fazer Pedido

        <div
          class="spinner-border spinner-border-sm text-light ms-1"
          role="status"
          v-if="loading"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
      </button>
    </div>
  </div>
  <!-- Coupon Modal -->
  <div
    class="modal fade"
    id="CouponModal"
    tabindex="-1"
    aria-labelledby="CouponModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content rounded-3">
        <div class="modal-header border-bottom-0">
          <h5 class="modal-title" id="CouponModalLabel">
            <i class="uicon ic_rr_ticket me-1"></i>Adicionar Cupom
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            <input
              type="text"
              id="coupon_code"
              v-model="data.coupon_code"
              :disabled="couponAdded"
              class="form-control"
              :class="{
                'is-invalid': errors.hasOwnProperty('coupon_code'),
                'is-valid': couponAdded,
              }"
              placeholder="Código de cupom"
              aria-label="Código de cupom"
              autocomplete="coupon_code"
            />
            <div
              class="invalid-feedback"
              v-if="errors.hasOwnProperty('coupon_code')"
            >
              {{ errors.coupon_code[0] }}
            </div>
            <div class="valid-feedback" v-if="couponAdded">Cupom válido</div>
          </div>
          <button
            class="btn btn-danger w-100"
            :disabled="data.coupon_code.length == 0 || couponAdded"
            @click="addCoupon"
          >
            Adicionar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { blr_money_format } from "@/services/utils";
import { Cart } from "@/services/cart";
const SOURCE_WEBSITE = 1;
export default {
  props: ["user"],
  data() {
    return {
      areas: [],
      payment_methods: [],
      data: {
        name: this.user.name || "",
        phone: this.user.phone || "",
        email: this.user.email || "",
        delivery: true,

        area:  this.user.area_id || "",
        street: this.user.street || "",
        street_number: this.user.street_number || "",
        neighborhood: this.user.neighborhood || "",
        complement: this.user.complement || "",

        payment_method: 0,
        coupon_code: "",
        comment: "",
        cart: { items: Cart.items(), total: Cart.total() },
        source: SOURCE_WEBSITE,
      },
      isDelivery: 1,
      couponAdded: false,
      couponDiscount: 0,
      coupon: "",
      loading: false,
      loadingAreas: false,
      loadingPaymentMethods: false,
      errors: {},
    };
  },
  methods: {
    storeOrder() {
      if (Cart.isEmpty()) {
        Swal.fire("Seu carrinho está vazio!", "", "warning").then(function () {
          window.location = "/cart";
        });
      }

      this.loading = true;
      topbar.show();
      this.errors = {};
      axios
        .post("/api/v1/order", this.data)
        .then((response) => {
          Swal.fire(
            "Pedido enviado com sucesso",
            "Muito obrigado por escolher a Narjes!",
            "success"
          ).then(function () {
            Cart.clear();
            window.location = "/";
          });
        })
        .catch((error) => {
          if (error.response.status === 422 || error.response.status === 429) {
            this.errors = error.response.data.errors;
          } else {
            Swal.fire(
              "",
              "Ocorreu um erro ao enviar seu pedido. Por favor, tente atualizar a página ou tente novamente mais tarde",
              "error"
            );
          }
        })
        .then(() => {
          topbar.hide();
          this.loading = false;
        });
    },

    fetchAreas() {
      this.loadingAreas = true;
      axios
        .get("/api/v1/areas")
        .then((response) => {
          this.areas = response.data.data;
        })
        .catch((error) => {})
        .then(() => {
          this.loadingAreas = false;
        });
    },
    fetchPaymentMethods() {
      this.loadingPaymentMethods = true;
      axios
        .get("/api/v1/payment-methods")
        .then((response) => {
          this.payment_methods = response.data.data;
          this.data.payment_method = this.paymentMethodsList[0].id;
        })
        .catch((error) => {})
        .then(() => {
          this.loadingPaymentMethods = false;
        });
    },
    selectChange() {
      this.isDelivery = this.data.delivery;
      this.data.payment_method = this.paymentMethodsList[0].id;
      this.couponDiscount = 0;
      this.couponAdded = false;
    },
    addCoupon() {
      topbar.show();
      this.errors = {};
      let requestData = {
        coupon_code: this.data.coupon_code,
        is_delivery: this.isDelivery,
      };
      axios
        .post("/api/v1/coupon", requestData)
        .then((response) => {
          this.coupon = response.data.data;
          this.couponDiscount =
            (this.$store.state.cartTotal * this.coupon.percentage) / 100;

          this.couponAdded = true;
        })
        .catch((error) => {
          this.errors = { coupon_code: ["Cupom inválido"] };
        })
        .then(() => {
          topbar.hide();
        });
    },
    currencyFormat(number) {
      return blr_money_format(parseFloat(number));
    },
  },
  created: function () {
    this.fetchAreas();
    this.fetchPaymentMethods();
  },
  mounted() {
    if (Cart.totalItems() == 0) {
      window.location = "/cart";
    }
  },
  computed: {
    orderSubtotal() {
      return this.$store.state.cartTotal;
    },
    orderTotal() {
      return this.isDelivery
        ? this.deliveryFee + this.orderSubtotal - this.couponDiscount
        : this.orderSubtotal - this.couponDiscount;
    },
    deliveryFee() {
      let deliveryArea = this.areas.find((area) => area.id === this.data.area);
      return deliveryArea ? deliveryArea.fee : 0;
    },
    isAreaSelected() {
      return !!this.data.area;
    },
    deliveryTime() {
      let deliveryArea = this.areas.find((area) => area.id === this.data.area);
      return deliveryArea ? deliveryArea.view_time : "0 min";
    },
    isDisable(input) {
      return input.length > 0;
    },
    couponDiscountPrice() {
      return this.couponDiscount;
    },
    paymentMethodsList() {
      return this.payment_methods.filter((method) => {
        return this.isDelivery == !!method.is_delivery;
      });
    },
    showSubtotal() {
      // if (!this.isAreaSelected) return this.currencyFormat(this.orderSubtotal);
      // return this.deliveryFee == 0 && this.isDelivery
      //   ? "Vamos Consultar"
      //   : this.currencyFormat(this.orderSubtotal);
      return this.currencyFormat(this.orderSubtotal);
    },
    showDiscount() {
      return this.deliveryFee == 0 && this.isDelivery
        ? "Vamos Consultar"
        : "-" + this.currencyFormat(this.couponDiscountPrice);
    },
    showTotal() {
      if (!this.isAreaSelected) return this.currencyFormat(this.orderTotal);

      return this.deliveryFee == 0 && this.isDelivery
        ? "Vamos Consultar"
        : this.currencyFormat(this.orderTotal);
    },
    showDeliveryFee() {
      if (!this.isAreaSelected) return blr_money_format(0);
      return this.deliveryFee > 0
        ? this.currencyFormat(this.deliveryFee)
        : "Vamos Consultar";
    },
  },
};
</script>
