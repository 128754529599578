<template>
  <div class="d-flex mb-3 justify-content-center">
    <div class="h3">Categorias</div>
    <div class="ms-auto">
      <button
        class="btn btn-primary px-4"
        data-bs-toggle="modal"
        data-bs-target="#categoryModal"
      >
        <i class="uicon ic_rr_plus"></i> Adicionar Categoria
      </button>
    </div>
  </div>
  <div class="card shadow-sm">
    <div class="card-body">
      <div class="position-relative mb-3">
        <input
          type="text"
          class="form-control w-auto"
          name="search"
          v-model="search"
          id="search"
          autocomplete="off"
          placeholder="Procurar..."
          style="padding-left: 2rem !important"
        />
        <div class="position-absolute top-50 start-0 translate-middle-y p-2">
          <i class="uicon ic_rr_search text-muted"></i>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
            <tr>
              <th class="text-muted small fw-normal">Categoria</th>
              <th class="text-muted small fw-normal">Ordem de classificação</th>
              <th class="text-muted small fw-normal">Status</th>
              <th class="text-muted small fw-normal"></th>
            </tr>
          </thead>
          <tbody class="border-top-0">
            <tr v-for="category in categoryList" :key="category.id">
              <td class="align-middle">{{ category.name }}</td>
              <td class="align-middle">{{ category.sort_order }}</td>
              <td class="align-middle">
                <div class="form-check form-switch">
                  <input
                    class="form-check-input cursor-pointer"
                    type="checkbox"
                    id="flexSwitchCheckChecked"
                    :checked="category.active"
                    v-on:change="updateStatus(category.id)"
                  />
                  <label class="form-check-label" for="flexSwitchCheckChecked">
                    {{ category.status }}
                  </label>
                </div>
              </td>
              <td class="align-middle">
                <button
                  class="btn btn-info btn-xs me-2"
                  data-bs-toggle="modal"
                  data-bs-target="#editCategoryModal"
                  v-on:click="openEditModal(category)"
                >
                  Editar
                </button>

                <button
                  class="btn btn-danger btn-xs"
                  v-on:click="deleteCategory(category.id)"
                >
                  Excluir
                </button>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="text-center" v-if="categoryList.length == 0">
          <h6>Sem dados disponíveis na tabela</h6>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Create -->
  <div
    class="modal fade"
    id="categoryModal"
    tabindex="-1"
    aria-labelledby="categoryModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content rounded-3">
        <div class="modal-header border-bottom-0 border-bottom-0">
          <h5 class="modal-title" id="categoryModalLabel">
            Adicionar categoria
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            v-on:click="restorFormControl"
          ></button>
        </div>
        <form @submit.prevent="storeCategory">
          <div class="modal-body">
            <div class="mb-3">
              <label for="name" class="form-label">Categoria</label>
              <input
                type="text"
                class="form-control"
                v-model="data.name"
                id="name"
                :class="{ 'is-invalid': errors.hasOwnProperty('name') }"
                name="name"
              />
              <div
                class="invalid-feedback"
                v-if="errors.hasOwnProperty('name')"
              >
                {{ errors.name[0] }}
              </div>
            </div>
            <div class="mb-3">
              <label for="sort-order" class="form-label">
                Ordem de classificação
              </label>
              <input
                type="number"
                class="form-control"
                v-model="data.sort_order"
                step="1"
                min="1"
                id="sort-order"
                :class="{ 'is-invalid': errors.hasOwnProperty('sort_order') }"
              />
              <div
                class="invalid-feedback"
                v-if="errors.hasOwnProperty('sort_order')"
              >
                {{ errors.sort_order[0] }}
              </div>
            </div>
          </div>
          <div class="modal-footer border-0">
            <button
              type="submit"
              class="btn btn-primary px-4"
              :disabled="loading"
            >
              Salve
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- Modal Update -->
  <div
    class="modal fade"
    id="editCategoryModal"
    tabindex="-1"
    aria-labelledby="editCategoryModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content rounded-3">
        <div class="modal-header border-bottom-0">
          <h5 class="modal-title" id="editCategoryModalLabel">
            Editar categoria
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            v-on:click="restorFormControl"
          ></button>
        </div>
        <form @submit.prevent="updateCategory(editData.id)">
          <div class="modal-body">
            <div class="mb-3">
              <label for="name-edit" class="form-label">Categoria</label>
              <input
                type="text"
                class="form-control"
                v-model="editData.name"
                id="name-edit"
                :class="{ 'is-invalid': errors.hasOwnProperty('name') }"
              />
              <div
                class="invalid-feedback"
                v-if="errors.hasOwnProperty('name')"
              >
                {{ errors.name[0] }}
              </div>
            </div>
            <div class="mb-3">
              <label for="sort-order-edit" class="form-label"
                >Ordem de classificação</label
              >
              <input
                type="number"
                class="form-control"
                v-model="editData.sort_order"
                step="1"
                min="1"
                id="sort-order-edit"
                :class="{ 'is-invalid': errors.hasOwnProperty('sort_order') }"
              />
              <div
                class="invalid-feedback"
                v-if="errors.hasOwnProperty('sort_order')"
              >
                {{ errors.sort_order[0] }}
              </div>
            </div>
          </div>

          <div class="modal-footer border-0">
            <button
              type="submit"
              class="btn btn-primary px-4"
              :disabled="loading"
            >
              Salve
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      categories: [],
      search: "",
      data: {
        name: "",
        sort_order: 1,
      },

      editData: {
        id: "",
        name: "",
        sort_order: 1,
        _method: "PUT",
      },
      errors: {},
      loading: false,
    };
  },
  mounted() {},
  methods: {
    fetchCategories() {
      topbar.show();
      axios
        .get("/admin/categories/all")
        .then((response) => {
          this.categories = response.data.data;
          // Set the initial number of items
          //this.totalRows = this.items.length;
        })
        .catch(({ response }) => {
          Swal.fire(`Error ${response.status}`, response.statusText, "error");
        })
        .then(() => {
          topbar.hide();
        });
    },
    refreshTable() {
      this.fetchCategories();
    },

    deleteCategory(id) {
      Swal.fire({
        title: "Você tem certeza?",
        text: "Você não poderá reverter isso!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Cancelar",
        focusCancel: true,
        showClass: {
          popup: "",
          icon: "",
        },
        hideClass: {
          popup: "",
        },
      }).then((result) => {
        if (result.value) {
          topbar.show();
          axios
            .delete("/admin/categories/" + id)
            .then((response) => {
              if (response.status == 200) {
                this.fetchCategories();
                this.$toast.success("Categoria Excluída");
              }
            })
            .catch(({ response }) => {
              Swal.fire(
                `Error ${response.status}`,
                response.statusText,
                "error"
              );
            })
            .then(() => {
              topbar.hide();
            });
        }
      });
    },
    storeCategory() {
      this.errors = {};
      topbar.show();
      this.loading = true;

      axios
        .post("/admin/categories", this.data)
        .then((response) => {
          this.fetchCategories();
          this.data.name = "";
          this.data.sort_order = 1;
          this.$toast.success("Categoria Adicionada");
        })
        .catch(({ response }) => {
          if (response.status === 422 || response.status === 429) {
            this.errors = response.data.errors;
          } else {
            Swal.fire(`Error ${response.status}`, response.statusText, "error");
          }
        })
        .then(() => {
          topbar.hide();
          this.loading = false;
        });
    },

    updateCategory(id) {
      this.errors = {};
      topbar.show();
      this.loading = true;

      axios
        .post("/admin/categories/" + id, this.editData)
        .then((response) => {
          this.fetchCategories();
          this.$toast.success("Categoria atualizada");
        })
        .catch(({ response }) => {
          if (response.status === 422 || response.status === 429) {
            this.errors = response.data.errors;
          } else {
            Swal.fire(`Error ${response.status}`, response.statusText, "error");
          }
        })
        .then(() => {
          topbar.hide();
          this.loading = false;
        });
    },
    openEditModal(category) {
      this.editData.id = category.id;
      this.editData.name = category.name;
      this.editData.sort_order = category.sort_order;
    },

    updateStatus(id) {
      topbar.show();
      axios
        .put("/admin/categories/status/" + id)
        .then((response) => {
          this.fetchCategories();
          this.$toast.success("Categoria atualizada");
        })
        .catch(({ response }) => {
          Swal.fire(`Error ${response.status}`, response.statusText, "error");
        })
        .then(() => {
          topbar.hide();
        });
    },
    restorFormControl() {
      this.errors = {};
    },
  },

  created: function () {
    this.fetchCategories();
  },
  computed: {
    categoryList() {
      const search = this.search.toLowerCase();
      if (!search) return this.categories;
      return this.categories.filter((category) => {
        return category.name.toLowerCase().includes(search);
      });
    },
  },
};
</script>
