<template>
  <div class="card mb-3 shadow-sm">
    <div class="card-body">
      <form @submit.prevent="updateAbout()">
        <div class="mb-3">
          <label for="about" class="form-label">
            <a href="/about" class="link-primary" target="_blank"> Sobre </a>
          </label>
          <ckeditor
            :editor="editor"
            v-model="aboutData.value"
            :config="editorConfig"
          ></ckeditor>
        </div>
        <button
          type="submit"
          class="btn btn-primary px-4"
          :disabled="loading"
        >
          Salve
        </button>
      </form>
    </div>
  </div>
  <div class="card mb-3 shadow-sm">
    <div class="card-body">
      <form @submit.prevent="updateGlobalAlert()">
        <div class="mb-3">
          <label for="about" class="form-label">
            Alerta Global
            <br />
            <small class="text-info">
              este alerta será mostrado na página inicial
            </small>
          </label>
          <ckeditor
            :editor="editor"
            v-model="globalAlertData.value"
            :config="editorConfig"
          ></ckeditor>
        </div>
        <button
          type="submit"
          class="btn btn-primary px-4"
          :disabled="loading"
        >
          Salve
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [
          "heading",
          "|",
          "bold",
          "italic",
          "link",
          "bulletedList",
          "numberedList",
          "blockQuote",
        ],
      },
      loading: false,

      aboutData: {
        value: "",
        _method: "PUT",
      },
      globalAlertData: {
        value: "",
        _method: "PUT",
      },
    };
  },
  created() {
    this.fetchSettings();
  },
  methods: {
    fetchSettings() {
      topbar.show();
      axios
        .get("/admin/settings/all")
        .then((response) => {
          this.aboutData.value = response.data.about;
          if (response.data.global_alert) {
            this.globalAlertData.value = response.data.global_alert;
          }
        })
        .catch(({ response }) => {
          Swal.fire(`Error ${response.status}`, response.statusText, "error");
        })
        .then(() => {
          topbar.hide();
        });
    },

    updateAbout() {
      topbar.show();
      this.loading = true;
      axios
        .post("/admin/settings/about", this.aboutData)
        .then((response) => {
          this.$toast.success("Sobre atualizada");
        })
        .catch(({ response }) => {
          if (response.status === 422 || response.status === 429) {
            this.$toast.error(response.data.errors);
          } else {
            Swal.fire(`Error ${response.status}`, response.statusText, "error");
          }
        })
        .then(() => {
          topbar.hide();
          this.loading = false;
        });
    },

    updateGlobalAlert() {
      topbar.show();
      this.loading = true;
      axios
        .post("/admin/settings/global_alert", this.globalAlertData)
        .then((response) => {
          this.$toast.success("Alerta Global atualizada");
        })
        .catch(({ response }) => {
          if (response.status === 422 || response.status === 429) {
            this.$toast.error(response.data.errors);
          } else {
            Swal.fire(`Error ${response.status}`, response.statusText, "error");
          }
        })
        .then(() => {
          topbar.hide();
          this.loading = false;
        });
    },
  },
  mounted() {},
};
</script>
