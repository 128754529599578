<template>
  <div class="d-flex mb-3 justify-content-center">
    <div class="h3">Itens</div>
    <div class="ms-auto">
      <button
        class="btn btn-primary px-4"
        data-bs-toggle="modal"
        data-bs-target="#itemModal"
      >
        <Icon icon="rr_plus" /> Adicionar Item
      </button>
    </div>
  </div>
  <div class="card shadow-sm">
    <div class="card-body">
      <div class="position-relative mb-3">
        <input
          type="text"
          class="form-control w-auto"
          name="search"
          v-model="search"
          id="search"
          autocomplete="off"
          placeholder="Procurar..."
          style="padding-left: 2rem !important"
        />
        <div class="position-absolute top-50 start-0 translate-middle-y p-2">
          <Icon icon="rr_search" styleName="text-muted" />
        </div>
      </div>

      <div>
        <DataTable
          :value="itemList"
          stripedRows
          showGridlines
          responsiveLayout="scroll"
          :paginator="true"
          :rows="20"
          :loading="loadingData"
        >
          <template #empty>
            <div class="text-center">
              <h6>Sem dados disponíveis na tabela</h6>
            </div>
          </template>

          <template #loading>
            <div class="text-center">
              <h6>Carregando...</h6>
            </div>
          </template>

          <Column field="name" header="Item" :sortable="true">
            <template #body="slotProps">
              <div class="d-flex align-items-center mb-2">
                <div class="flex-shrink-0">
                  <a :href="slotProps.data.url" target="_blank">
                    <div class="item-image-wrapper-sm">
                      <picture>
                        <source
                          type="image/jpg"
                          :srcset="slotProps.data.image_url"
                        />
                        <img
                          :alt="slotProps.data.name"
                          :src="slotProps.data.image_url"
                          aria-hidden="true"
                          class="img-sm rounded-circle"
                        />
                      </picture>
                    </div>
                  </a>
                </div>
                <div class="flex-grow-1 ms-2">
                  <a
                    :href="slotProps.data.url"
                    class="link-primary"
                    target="_blank"
                  >
                    {{ slotProps.data.name }}
                  </a>
                </div>
              </div>
              <div>
                <property-badge-component
                  :item="slotProps.data"
                ></property-badge-component>
              </div>
            </template>
          </Column>
          <Column
            field="category.name"
            header="Categoria"
            :sortable="true"
          ></Column>
          <Column field="price" header="Preço" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.view_price }}
              <DiscountBadge v-if="slotProps.data.has_discount"></DiscountBadge>
            </template>
          </Column>

          <Column field="active" header="Status" :sortable="true">
            <template #body="slotProps">
              <div class="form-check form-switch">
                <input
                  class="form-check-input cursor-pointer"
                  type="checkbox"
                  id="flexSwitchCheckChecked"
                  :checked="slotProps.data.active"
                  v-on:change="updateStatus(slotProps.data.id)"
                />
                <label class="form-check-label" for="flexSwitchCheckChecked">
                  {{ slotProps.data.status }}
                </label>
              </div>
            </template>
          </Column>
          <Column header=" " :sortable="false">
            <template #body="slotProps">
              <div class="dropdown">
                <button
                  class="btn btn-link text-dark"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <Icon icon="rr_menu_dots_vertical" />
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <li>
                    <button
                      class="dropdown-item"
                      v-on:click="copyLinkToClipBoard(slotProps.data)"
                    >
                      <Icon icon="rr_link" styleName="me-1" /> Link de cópia
                    </button>
                  </li>

                  <li>
                    <a
                      class="dropdown-item"
                      :href="slotProps.data.url"
                      target="_blank"
                    >
                      <Icon icon="rr_interactive" styleName="me-1" />
                      Abrir em uma nova aba
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      :href="
                        'https://api.qrserver.com/v1/create-qr-code/?size=300x300&data=' +
                        slotProps.data.url
                      "
                      target="_blank"
                    >
                      <Icon icon="br_mode_landscape" styleName="me-1" />
                      QR Code
                    </a>
                  </li>
                  <li>
                    <button
                      class="dropdown-item"
                      @click="shareToFacebook(slotProps.data.url)"
                    >
                      <Icon icon="facebook" styleName="text-facebook me-1" />
                      Partilhar no Facebook
                    </button>
                  </li>
                  <li>
                    <button
                      class="dropdown-item"
                      @click="tweet(slotProps.data.url)"
                    >
                      <Icon icon="twitter" styleName="text-twitter me-1" />
                      Tweet sobre isso
                    </button>
                  </li>
                  <li>
                    <button
                      class="dropdown-item"
                      @click="sendViaWhatsApp(slotProps.data.url)"
                    >
                      <Icon icon="whatsapp" styleName="text-whatsapp me-1" />
                      Enviar via WhatsApp
                    </button>
                  </li>
                  <li>
                    <hr class="dropdown-divider" />
                  </li>
                  <li>
                    <button
                      class="dropdown-item text-info"
                      data-bs-toggle="modal"
                      data-bs-target="#editItemModal"
                      v-on:click="openEditModal(slotProps.data)"
                    >
                      <Icon icon="rr_edit" styleName="me-1" />
                      Editar
                    </button>
                  </li>

                  <li>
                    <button
                      class="dropdown-item"
                      v-on:click="replicate(slotProps.data.id)"
                    >
                      <Icon icon="rr_copy_alt" styleName="me-1" />
                      Replicar
                    </button>
                  </li>
                  <li>
                    <hr class="dropdown-divider" />
                  </li>
                  <li>
                    <button
                      class="dropdown-item text-danger"
                      v-on:click="deleteItem(slotProps.data.id)"
                    >
                      <Icon icon="rr_trash" styleName="me-1" />
                      Excluir
                    </button>
                  </li>
                </ul>
              </div>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>

  <!-- Modal Create -->
  <div
    class="modal fade"
    id="itemModal"
    tabindex="-1"
    aria-labelledby="itemModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content rounded-3">
        <div class="modal-header border-bottom-0">
          <h5 class="modal-title" id="itemModalLabel">Adicionar Item</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            v-on:click="restorFormControl"
          ></button>
        </div>

        <form @submit.prevent="storeItem">
          <div class="modal-body">
            <div class="mb-3 text-center">
              <label
                for="input-image"
                class="cursor-pointer position-relative rounded-3"
                style="width: 140px; height: 140px"
                :class="{
                  'border border-dark': !imageUrl,
                }"
              >
                <img
                  :src="imageUrl"
                  class="img-lg border border-dark rounded-3"
                  alt="placeholder-image"
                  v-if="imageUrl"
                />
                <div
                  class="position-absolute top-50 start-50 translate-middle"
                  v-if="!imageUrl"
                >
                  <div class="fw-bold">Adicionar Foto</div>
                </div>
              </label>

              <div class="text-danger" v-if="errors.hasOwnProperty('image')">
                {{ errors.image[0] }}
              </div>
              <input
                id="input-image"
                class="d-none"
                type="file"
                name="input-image"
                accept="image/x-png, image/jpeg, image/jpg"
                value=""
                @change="openImage"
              />
            </div>

            <div class="mb-3">
              <label for="category" class="form-label">Categoria </label>
              <a
                href="#"
                class="link-primary float-end"
                data-bs-toggle="modal"
                data-bs-target="#addCategoryModal"
                >+ Adicionar nova categoria</a
              >
              <select
                class="form-select rounded-main"
                v-model="data.category"
                id="category"
                :class="{ 'is-invalid': errors.hasOwnProperty('category') }"
                name="category"
              >
                <option
                  v-for="category in categories"
                  :key="category.id"
                  :value="category.id"
                >
                  {{ category.name }}
                </option>
              </select>

              <div
                class="invalid-feedback"
                v-if="errors.hasOwnProperty('category')"
              >
                {{ errors.category[0] }}
              </div>
            </div>

            <div class="mb-3">
              <label for="name" class="form-label">Item</label>
              <input
                type="text"
                class="form-control"
                v-model="data.name"
                id="name"
                :class="{ 'is-invalid': errors.hasOwnProperty('name') }"
                name="name"
              />
              <div
                class="invalid-feedback"
                v-if="errors.hasOwnProperty('name')"
              >
                {{ errors.name[0] }}
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 mb-3">
                <label for="price" class="form-label"> Preço </label>
                <input
                  type="number"
                  class="form-control"
                  v-model="data.price"
                  step="0.1"
                  min="0"
                  id="price"
                  :class="{ 'is-invalid': errors.hasOwnProperty('price') }"
                />
                <div
                  class="invalid-feedback"
                  v-if="errors.hasOwnProperty('price')"
                >
                  {{ errors.price[0] }}
                </div>
              </div>

              <div class="col-md-6 mb-3">
                <label for="discount" class="form-label"> Desconto </label>
                <input
                  type="number"
                  class="form-control"
                  v-model="data.discount"
                  step="0.1"
                  min="0"
                  id="discount"
                  :class="{ 'is-invalid': errors.hasOwnProperty('discount') }"
                />
                <div
                  class="invalid-feedback"
                  v-if="errors.hasOwnProperty('discount')"
                >
                  {{ errors.discount[0] }}
                </div>
              </div>
            </div>

            <div class="mb-3">
              <!-- <label for="des" class="form-label"> Descrição </label>
              <textarea
                class="form-control"
                v-model="data.des"
                step="0.1"
                min="0"
                id="des"
                :class="{ 'is-invalid': errors.hasOwnProperty('des') }"
              ></textarea>
              <div class="invalid-feedback" v-if="errors.hasOwnProperty('des')">
                {{ errors.des[0] }}
              </div> -->
              <label for="des" class="form-label"> Descrição </label>
              <ckeditor
                :editor="editor"
                v-model="data.des"
                :config="editorConfig"
              ></ckeditor>
              <div class="text-danger" v-if="errors.hasOwnProperty('des')">
                {{ errors.des[0] }}
              </div>
            </div>
          </div>
          <div class="modal-footer border-0">
            <button
              type="submit"
              class="btn btn-primary px-4"
              :disabled="loading"
            >
              Salve
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- Modal Update -->
  <div
    class="modal fade"
    id="editItemModal"
    tabindex="-1"
    aria-labelledby="editItemModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content rounded-3">
        <div class="modal-header border-bottom-0">
          <h5 class="modal-title" id="editItemModalLabel">Editar Item</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            v-on:click="restorFormControl"
          ></button>
        </div>
        <form @submit.prevent="updateItem(editData.id)">
          <div class="modal-body">
            <div class="mb-3 text-center">
              <label
                for="input-image-edit"
                class="cursor-pointer position-relative border border-dark rounded-3"
                style="width: 140px; height: 140px"
              >
                <img
                  :src="editData.image_url"
                  class="img-lg rounded-3"
                  alt="placeholder-image "
                  v-if="editData.image_url"
                />
                <div
                  class="position-absolute top-50 start-50 translate-middle"
                  v-if="!editData.image_url"
                >
                 <div class="fw-bold">Adicionar Foto</div>
                </div>
              </label>

              <div class="text-danger" v-if="errors.hasOwnProperty('image')">
                {{ errors.image[0] }}
              </div>
              <input
                id="input-image-edit"
                class="d-none"
                type="file"
                name="input-image-edit"
                accept="image/x-png, image/jpeg, image/jpg"
                value=""
                @change="openImageEdit"
              />
            </div>

            <div class="mb-3">
              <label for="category-edit" class="form-label">Categoria </label>

              <select
                class="form-select rounded-main"
                v-model="editData.category"
                id="category-edit"
                :class="{ 'is-invalid': errors.hasOwnProperty('category') }"
                name="category"
              >
                <option
                  v-for="category in categories"
                  :key="category.id"
                  :value="category.id"
                >
                  {{ category.name }}
                </option>
              </select>

              <div
                class="invalid-feedback"
                v-if="errors.hasOwnProperty('category')"
              >
                {{ errors.category[0] }}
              </div>
            </div>

            <div class="mb-3">
              <label for="name-edit" class="form-label">Item</label>
              <input
                type="text"
                class="form-control"
                v-model="editData.name"
                id="name-edit"
                :class="{ 'is-invalid': errors.hasOwnProperty('name') }"
                name="name"
              />
              <div
                class="invalid-feedback"
                v-if="errors.hasOwnProperty('name')"
              >
                {{ errors.name[0] }}
              </div>
            </div>
            <div class="mb-3">
              <label for="slug-edit" class="form-label">Item Link</label>

              <div class="input-group">
                <span class="input-group-text bg-light pe-0" id="basic-addon3">
                  https://narjes.com.br/
                  </span>
                <input
                  type="text"
                  class="form-control ps-0"
                  v-model="editData.slug"
                  id="slug-edit"
                  :class="{ 'is-invalid': errors.hasOwnProperty('slug') }"
                  name="slug"
                />
                <div
                  class="invalid-feedback"
                  v-if="errors.hasOwnProperty('slug')"
                >
                  {{ errors.slug[0] }}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 mb-3">
                <label for="price-edit" class="form-label"> Preço </label>
                <input
                  type="number"
                  class="form-control"
                  v-model="editData.price"
                  step="0.1"
                  min="0"
                  id="price-edit"
                  :class="{ 'is-invalid': errors.hasOwnProperty('price') }"
                />
                <div
                  class="invalid-feedback"
                  v-if="errors.hasOwnProperty('price')"
                >
                  {{ errors.price[0] }}
                </div>
              </div>

              <div class="col-md-6 mb-3">
                <label for="discount-edit" class="form-label"> Desconto </label>
                <input
                  type="number"
                  class="form-control"
                  v-model="editData.discount"
                  step="0.1"
                  min="0"
                  id="discount-edit"
                  :class="{ 'is-invalid': errors.hasOwnProperty('discount') }"
                />
                <div
                  class="invalid-feedback"
                  v-if="errors.hasOwnProperty('discount')"
                >
                  {{ errors.discount[0] }}
                </div>
              </div>
            </div>

            <div class="mb-3">
              <!-- <label for="des-edit" class="form-label"> Descrição </label>
              <textarea
                class="form-control"
                v-model="editData.des"
                step="0.1"
                min="0"
                id="des-edit"
                :class="{ 'is-invalid': errors.hasOwnProperty('des') }"
              ></textarea>
              <div class="invalid-feedback" v-if="errors.hasOwnProperty('des')">
                {{ errors.des[0] }}
              </div> -->
              <label for="des-edit" class="form-label"> Descrição </label>
              <ckeditor
                :editor="editor"
                v-model="editData.des"
                :config="editorConfig"
              ></ckeditor>
              <div class="text-danger" v-if="errors.hasOwnProperty('des')">
                {{ errors.des[0] }}
              </div>
            </div>
          </div>
          <div class="modal-footer border-0">
            <button
              type="submit"
              class="btn btn-primary px-4"
              :disabled="loading"
            >
              Salve
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- Add Category Modal -->
  <div
    class="modal fade"
    id="addCategoryModal"
    tabindex="-1"
    aria-labelledby="addCategoryModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content rounded-3">
        <div class="modal-header border-bottom-0">
          <h5 class="modal-title" id="addCategoryModalLabel">
            Adicionar categoria
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <form @submit.prevent="storeCategory">
          <div class="modal-body">
            <div class="mb-3">
              <label for="name" class="form-label">Categoria</label>
              <input
                type="text"
                class="form-control"
                v-model="categoryData.name"
                id="category-name"
                :class="{ 'is-invalid': errors.hasOwnProperty('name') }"
                name="name"
              />
              <div
                class="invalid-feedback"
                v-if="errors.hasOwnProperty('name')"
              >
                {{ errors.name[0] }}
              </div>
            </div>
            <div class="mb-3">
              <label for="sort-order" class="form-label">
                Ordem de classificação
              </label>
              <input
                type="number"
                class="form-control"
                v-model="categoryData.sort_order"
                step="1"
                min="1"
                id="sort-order"
                :class="{ 'is-invalid': errors.hasOwnProperty('sort_order') }"
              />
              <div
                class="invalid-feedback"
                v-if="errors.hasOwnProperty('sort_order')"
              >
                {{ errors.sort_order[0] }}
              </div>
            </div>
          </div>
          <div class="modal-footer border-0">
            <button
              type="submit"
              class="btn btn-primary px-4"
              :disabled="loading"
            >
              Salve
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import PropertyBadgeComponent from "../Shared/PropertyBadgeComponent";
export default {
  components: { PropertyBadgeComponent },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [
          "heading",
          "|",
          "bold",
          "italic",
          "link",
          "bulletedList",
          "numberedList",
          "blockQuote",
        ],
      },
      items: [],
      categories: [],
      pageOfItems: [],
      search: "",
      categoryData: {
        name: "",
        sort_order: 1,
      },
      data: {
        name: "",
        image: "",
        des: "",
        price: 0,
        discount: 0,
        category: "",
      },
      imageUrl: "",
      editData: {
        name: "",
        slug: "",
        image: "",
        image_url: "",
        image_path: "",
        des: "",
        price: 0,
        discount: 0,
        category: "",
      },
      errors: {},
      loading: false,
      loadingData: true,
    };
  },
  mounted() {},
  methods: {
    fetchItems() {
      topbar.show();
      axios
        .get("/admin/items/all")
        .then((response) => (this.items = response.data.data))
        .catch(({ response }) => {
          Swal.fire(`Error ${response.status}`, response.statusText, "error");
        })
        .finally(() => {
          topbar.hide();
          this.loadingData = false;
        });
    },

    fetchCategories() {
      axios
        .get("/admin/categories/all")
        .then((response) => {
          this.categories = response.data.data;
        })
        .catch(({ response }) => {
          Swal.fire("Error ", "Could not fetch categories", "error");
        });
    },
    refreshTable() {
      this.fetchItems();
    },

    deleteItem(id) {
      Swal.fire({
        title: "Você tem certeza?",
        text: "Você não poderá reverter isso!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Cancelar",
        focusCancel: true,
        showClass: {
          popup: "",
          icon: "",
        },
        hideClass: {
          popup: "",
        },
      }).then((result) => {
        if (result.value) {
          topbar.show();
          axios
            .delete(`/admin/items/${id}`)
            .then((response) => {
              if (response.status == 200) {
                this.fetchItems();
                this.$toast.success("Item Excluída");
              }
            })
            .catch(({ response }) => {
              Swal.fire(
                "Error " + response.status,
                response.statusText,
                "error"
              );
            })
            .then(() => {
              topbar.hide();
            });
        }
      });
    },
    storeItem() {
      this.errors = {};
      topbar.show();
      this.loading = true;
      const formData = new FormData();
      formData.append("image", this.data.image);
      formData.append("name", this.data.name);
      formData.append("price", this.data.price);
      formData.append("des", this.data.des || "");
      formData.append("discount", this.data.discount);
      formData.append("category", this.data.category);
      axios
        .post("/admin/items", formData)
        .then((response) => {
          this.fetchItems();
          this.resetForm();
          this.$toast.success("Item Adicionada");
        })
        .catch(({ response }) => {
          if (response.status === 422 || response.status === 429) {
            this.errors = response.data.errors;
          } else {
            Swal.fire(`Error ${response.status}`, response.statusText, "error");
          }
        })
        .then(() => {
          topbar.hide();
          this.loading = false;
        });
    },
    storeCategory() {
      this.errors = {};
      topbar.show();
      this.loading = true;

      axios
        .post("/admin/categories", this.categoryData)
        .then((response) => {
          this.fetchCategories();
          this.categoryData.name = "";
          this.categoryData.sort_order = 1;
          this.$toast.success("Categoria Adicionada");
        })
        .catch(({ response }) => {
          if (response.status === 422 || response.status === 429) {
            this.errors = response.data.errors;
          } else {
            Swal.fire(`Error ${response.status}`, response.statusText, "error");
          }
        })
        .then(() => {
          topbar.hide();
          this.loading = false;
        });
    },
    updateItem(id) {
      this.errors = {};
      topbar.show();
      this.loading = true;
      const formData = new FormData();
      formData.append("image", this.editData.image);
      formData.append("name", this.editData.name);
      formData.append("price", this.editData.price);
      formData.append("slug", this.editData.slug);
      formData.append("des", this.editData.des || "");
      formData.append("discount", this.editData.discount);
      formData.append("category", this.editData.category);
      formData.append("_method", "PUT");
      axios
        .post("/admin/items/" + id, formData)
        .then((response) => {
          this.fetchItems();
          this.$toast.success("Item atualizada");
        })
        .catch(({ response }) => {
          if (response.status === 422 || response.status === 429) {
            this.errors = response.data.errors;
          } else {
            Swal.fire(`Error ${response.status}`, response.statusText, "error");
          }
        })
        .then(() => {
          topbar.hide();
          this.loading = false;
        });
    },
    openEditModal(item) {
      this.editData.id = item.id;
      this.editData.name = item.name;
      this.editData.slug = item.slug;
      this.editData.image = "";
      if (item.image_path) {
        this.editData.image_url = item.image_url;
        this.editData.image_path = item.image_path;
      } else {
        this.editData.image_url = null;
        this.editData.image_path = null;
      }
      this.editData.price = item.price;
      this.editData.discount = item.discount;
      this.editData.des = item.des;
      this.editData.category = item.category.id;
    },

    updateStatus(id) {
      topbar.show();
      axios
        .put(`/admin/items/status/${id}`)
        .then((response) => {
          this.fetchItems();
          this.$toast.success("Item atualizada");
        })
        .catch(({ response }) => {
          Swal.fire(`Error ${response.status}`, response.statusText, "error");
        })
        .finally(() => topbar.hide());
    },
    restorFormControl() {
      this.errors = {};
    },
    openImage(event) {
      var file = event.target.files[0];
      if (file) {
        this.data.image = file;
        this.imageUrl = URL.createObjectURL(this.data.image);
      } else {
        this.data.image = this.data.image;
        this.imageUrl = this.imageUrl;
      }
    },
    openImageEdit(event) {
      var file = event.target.files[0];
      if (file) {
        this.editData.image = file;
        this.editData.image_url = URL.createObjectURL(this.editData.image);
      } else {
        this.editData.image = this.editData.image;
        this.editData.image_url = this.editData.image_url;
      }
    },
    replicate(id) {
      Swal.fire({
        title: "Replicar este item?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Cancelar",
        focusCancel: true,
        showClass: {
          popup: "",
          icon: "",
        },
        hideClass: {
          popup: "",
        },
      }).then((result) => {
        if (result.value) {
          topbar.show();
          axios
            .post(`/admin/items/replicate/${id}`, null)
            .then((response) => {
              this.fetchItems();
              this.$toast.success("Item Replicado");
            })
            .catch(({ response }) => {
              Swal.fire(
                `Error ${response.status}`,
                response.statusText,
                "error"
              );
            })
            .finally(() => topbar.hide());
        }
      });
    },
    resetForm() {
      this.data.name = "";
      this.data.image = "";
      this.data.price = 0;
      this.data.des = "";
      this.data.discount = 0;
      this.data.category = "";
      this.imageUrl = "";
    },
    copyLinkToClipBoard(item) {
      var input = document.createElement("textarea");
      input.innerHTML = item.url;
      document.body.appendChild(input);
      input.select();
      var result = document.execCommand("copy");
      document.body.removeChild(input);
      this.$toast.success("Copiado para a área de transferência");
    },
    shareToFacebook(url) {
      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${url}`,
        "_blank"
      );
    },
    tweet(url) {
      window.open(`https://twitter.com/intent/tweet?text=${url}`, "_blank");
    },
    sendViaWhatsApp(url) {
      window.open(`https://wa.me/?text=${url}`, "_blank");
    },
  },

  created: function () {
    this.fetchItems();
    this.fetchCategories();
  },
  computed: {
    itemList() {
      const search = this.search.toLowerCase();
      if (!search) return this.items;
      return this.items.filter((item) => {
        return (
          item.name.toLowerCase().includes(search) ||
          item.category.name.toLowerCase().includes(search)
        );
      });
    },
  },
};
</script>