<template>
  <div class="text-center py-5">
    <img src="/images/cart.png" height="180" class="mb-2" alt="cart" />
    <div class="h3 mb-3">
      <div>Seu carrinho está vazio.</div>
      <div>Adicione seus itens de preferência</div>
      <div>e volte aqui para finalizar o seu pedido.</div>
    </div>
    <a href="/" class="btn btn-light fw-bold px-4"> Cardápio </a>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
