<template>
  <img
    src="/images/discount.svg"
    width="20"
    height="20"
    alt="discount"
    :class="`ms-2 ${styleName}`"
  />
</template>

<script>
export default {
  props: ["styleName"],
  data() {
    return {};
  },
  created() {},
  methods: {},
  mounted() {},
  computed: {},
};
</script>
