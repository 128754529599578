<template>
  <a class="btn btn-danger btn-sm px-3" href="/cart">
    <i class="uicon ic_sr_shopping_cart fs-6"></i>
    <span class="ms-3">{{ totalItems }}</span>
  </a>
</template>
<script>
import { blr_money_format } from "@/services/utils";

export default {
  data() {
    return {};
  },
  methods: {},
  created: function () {},
  computed: {
    total() {
      return blr_money_format(this.$store.state.cartTotal);
    },
    totalItems() {
      return this.$store.state.cartTotalItems;
    },
  },
};
</script>