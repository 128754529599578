<template>
  <div
    class="modal fade"
    id="itemModal"
    ref="itemModal"
    tabindex="-1"
    aria-labelledby="itemModalLabel"
    aria-hidden="true"
  >
    <div
      class="
        modal-dialog
        modal-dialog-centered
        modal-dialog-scrollable
        modal-fullscreen-md-down
      "
      style="max-width: 560px"
    >
      <div class="modal-content modal-rounded">
        <div class="modal-header py-2 border-0">
          <button
            type="button"
            class="btn-close m-0 me-auto p-1"
            data-bs-dismiss="modal"
            aria-label="Close"
            ref="itemModalCloseButton"
          ></button>
          <div class="dropdown">
            <button
              class="btn btn-link text-black m-0 ms-auto p-1"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="uicon ic_rr_share"></i>
            </button>
            <ul
              class="dropdown-menu rounded-3 slideIn animate"
              aria-labelledby="dropdownMenuButton1"
            >
              <li>
                <button class="dropdown-item" @click="shareToFacebook()">
                  <i class="uicon ic_facebook text-facebook me-1"></i> Partilhar
                  no Facebook
                </button>
              </li>
              <li>
                <button class="dropdown-item" @click="tweet()">
                  <i class="uicon ic_twitter text-twitter me-1"></i>Tweet sobre
                  isso
                </button>
              </li>
              <li>
                <button class="dropdown-item" @click="sendViaWhatsApp()">
                  <i class="uicon ic_whatsapp text-whatsapp me-1"></i> Enviar
                  via WhatsApp
                </button>
              </li>
            </ul>
          </div>
        </div>

        <div class="modal-body">
          <div>
            <a
              :href="item.url"
              class="
                fs-3
                text-decoration-none text-black
                fw-bold
                text-break
                item-name
              "
              :id="`${item.slug}ModalLabel`"
            >
              {{ item.name }}
            </a>
          </div>
          <div class="text-muted small mb-3">
            {{ item.category_name }}
          </div>

          <div class="mb-3">
            <property-badge-component :item="item"></property-badge-component>
          </div>
          <div class="modal-item-image-wrapper mb-3">
            <img
              :src="item.image_url"
              :alt="item.name"
              class="w-100 h-100 object-fit-cover rounded-main"
            />
          </div>

          <div class="mb-3" v-html="item.des"></div>

          <div class="mb-3">
            <label for="item-comment" class="form-label">
              Algum comentário?
            </label>
            <textarea
              id="item-comment"
              class="form-control"
              rows="3"
              v-model="comments"
              :maxlength="maxCharacters"
            ></textarea>
          </div>

          <span class="text-muted float-end">
            {{ comments.length }}/{{ maxCharacters }}
          </span>
        </div>
        <div class="modal-footer shadow">
          <div
            class="
              d-flex
              justify-content-center
              align-items-center
              me-2
              user-select-none
            "
          >
            <!-- <i class="uicon ic_rr_minus_small cursor-pointer fs-5" @click="decrement()"></i> -->
           <minus-button-component @click="decrement()"></minus-button-component>
            <div class="px-4 mx-3 py-2 flex-fill text-center rounded-2 my-0 bg-light">
              {{ quantity }}
            </div>
            <plus-button-component @click="increment()"></plus-button-component>
          </div>
          <button
            type="button"
            class="btn btn-danger px-3"
            v-on:click="addToCart()"
          >
            Adicionar - {{ price }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { Cart } from "@/services/cart";
import { blr_money_format } from "@/services/utils";
import PropertyBadgeComponent from "./Shared/PropertyBadgeComponent.vue";
import PlusButtonComponent from "./Shared/PlusButtonComponent.vue";
import MinusButtonComponent from "./Shared/MinusButtonComponent.vue";

export default {
  props: ["item"],
  components: { PropertyBadgeComponent, PlusButtonComponent, MinusButtonComponent },
  data() {
    return {
      quantity: 1,
      comments: "",
      maxCharacters: 155,
      modal: null,
    };
  },
  mounted() {
    this.modal = new Bootstrap.Modal(this.$refs.itemModal);
    this.$refs.itemModal.addEventListener("shown.bs.modal", function () {
      window.location.hash = "item";
    });
    this.$refs.itemModal.addEventListener("hidden.bs.modal", function () {
      window.location.href.split("#")[0];
    });
    var itemModal = this.modal;
    window.addEventListener("hashchange", function () {
      if (window.location.hash != `#item`) {
        itemModal.hide();
      }
    });
  },
  methods: {
    addToCart() {
      topbar.show();
      Cart.add(this.item, this.quantity, this.comments);
      this.$store.state.cartTotal = Cart.total();
      this.$store.state.cartTotalItems = Cart.totalItems();
      this.quantity = 1;
      this.comments = "";
      topbar.hide();
      this.modal.hide();
      this.$toast.show("Item Adicionado Ao Carrinho");
    },

    increment() {
      if (this.quantity > 100) return;
      this.quantity++;
    },

    decrement() {
      if (this.quantity < 2) return;
      this.quantity--;
    },
    shareToFacebook() {
      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${this.item.url}`,
        "_blank"
      );
    },
    tweet() {
      window.open(
        `https://twitter.com/intent/tweet?text=${this.item.url}`,
        "_blank"
      );
    },
    sendViaWhatsApp() {
      window.open(`https://wa.me/?text=${this.item.url}`, "_blank");
    },
  },
  computed: {
    price() {
      return blr_money_format(
        parseFloat(this.item.base_price) * parseInt(this.quantity)
      );
    },
  },
};
</script>
