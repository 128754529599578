<template>
  <div class="d-flex mb-3 justify-content-center">
    <div class="h3">Banners</div>
    <div class="ms-auto">
      <button
        class="btn btn-primary px-4"
        data-bs-toggle="modal"
        data-bs-target="#itemModal"
      >
        Adicionar novo banner
      </button>
    </div>
  </div>
  <div class="card shadow-sm">
    <div class="card-body">
      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
            <tr>
              <th class="text-muted small fw-normal">Imagem</th>
              <th class="text-muted small fw-normal">Ordem de classificação</th>
              <th class="text-muted small fw-normal">Status</th>
              <th class="text-muted small fw-normal"></th>
            </tr>
          </thead>
          <tbody class="border-top-0">
            <tr v-for="banner in bannerList" :key="banner.id">
              <td class="align-middle">
                <img
                  :src="banner.image_url"
                  alt="banner"
                  class="object-fit-cover rounded-3"
                  style="width: 560px; height: 190px"
                />
              </td>
              <td class="align-middle">{{ banner.sort_order }}</td>
              <td class="align-middle">
                <div class="form-check form-switch">
                  <input
                    class="form-check-input cursor-pointer"
                    type="checkbox"
                    id="flexSwitchCheckChecked"
                    :checked="banner.active"
                    v-on:change="updateStatus(banner.id)"
                  />
                  <label class="form-check-label" for="flexSwitchCheckChecked">
                    {{ banner.status }}
                  </label>
                </div>
              </td>
              <td class="align-middle">
                <button
                  class="btn btn-info btn-xs me-md-2"
                  data-bs-toggle="modal"
                  data-bs-target="#editBannerModal"
                  v-on:click="openEditModal(banner)"
                >
                  Editar
                </button>

                <button
                  class="btn btn-danger btn-xs"
                  v-on:click="deleteBanner(banner.id)"
                >
                  Excluir
                </button>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="text-center" v-if="bannerList.length == 0">
          <h6>Sem dados</h6>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Create -->
  <div
    class="modal fade"
    id="itemModal"
    tabindex="-1"
    aria-labelledby="itemModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content rounded-3">
        <div class="modal-header border-bottom-0">
          <h5 class="modal-title" id="itemModalLabel">Adicionar novo</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            v-on:click="restoreFormControl"
          ></button>
        </div>

        <form @submit.prevent="storeBanner">
          <div class="modal-body">
            <div class="mb-3 text-center">
              <label
                for="input-image"
                class="cursor-pointer position-relative rounded-3"
                :class="{
                  'border border-dark': !imageUrl,
                }"
                style="width: 560px; height: 190px"
              >
                <img
                  :src="imageUrl"
                  class="rounded-3 object-fit-cover border border-dark"
                  alt="placeholder-image"
                  style="width: 560px; height: 190px"
                  v-if="imageUrl"
                />
                <div
                  class="position-absolute top-50 start-50 translate-middle"
                  v-if="!imageUrl"
                >
                  <div class="fw-bold">Escolha a imagem</div>
                </div>
              </label>

              <div class="text-danger" v-if="errors.hasOwnProperty('image')">
                {{ errors.image[0] }}
              </div>
              <input
                id="input-image"
                class="d-none"
                type="file"
                name="input-image"
                accept="image/x-png, image/jpeg, image/jpg"
                value=""
                @change="openImage"
              />
            </div>

            <div class="mb-3">
              <label for="sort_order" class="form-label">
                Ordem de classificação
              </label>
              <input
                type="number"
                class="form-control"
                v-model="data.sort_order"
                step="1"
                min="0"
                F
                id="sort_order"
                :class="{ 'is-invalid': errors.hasOwnProperty('sort_order') }"
              />
              <div
                class="invalid-feedback"
                v-if="errors.hasOwnProperty('sort_order')"
              >
                {{ errors.sort_order[0] }}
              </div>
            </div>
            <div class="mb-3">
              <label for="link" class="form-label">Link</label>
              <input
                type="text"
                class="form-control"
                v-model="data.link"
                id="link"
                :class="{ 'is-invalid': errors.hasOwnProperty('link') }"
                name="link"
              />
              <div
                class="invalid-feedback"
                v-if="errors.hasOwnProperty('link')"
              >
                {{ errors.link[0] }}
              </div>
            </div>
            <div class="mb-3" v-if="data.link">
              <label for="target" class="form-label">Link alvo</label>
              <select
                class="form-select"
                :class="{ 'is-invalid': errors.hasOwnProperty('target') }"
                name="target"
                v-model="data.target"
                id="target"
              >
                <option value="_self">_self</option>
                <option value="_blank">_blank</option>
              </select>
              <div
                class="invalid-feedback"
                v-if="errors.hasOwnProperty('target')"
              >
                {{ errors.target[0] }}
              </div>
            </div>
          </div>
          <div class="modal-footer border-0">
            <button
              type="submit"
              class="btn btn-primary px-4"
              :disabled="loading"
            >
              Salve
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- Modal Update -->
  <div
    class="modal fade"
    id="editBannerModal"
    tabindex="-1"
    aria-labelledby="editBannerModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content rounded-3">
        <div class="modal-header border-bottom-0">
          <h5 class="modal-title" id="editBannerModalLabel">Editar Banner</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            v-on:click="restoreFormControl"
          ></button>
        </div>
        <form @submit.prevent="updateBanner(editData.id)">
          <div class="modal-body">
            <div class="mb-3 text-center">
              <label
                for="input-image-edit"
                class="cursor-pointer position-relative rounded-3"
                :class="{
                  'border border-dark': !editData.image_url,
                }"
                style="width: 560px; height: 190px"
              >
                <img
                  :src="editData.image_url"
                  class="rounded-3 object-fit-cover border border-dark"
                  alt="placeholder-image"
                  style="width: 560px; height: 190px"
                  v-if="editData.image_url"
                />
                <div
                  class="position-absolute top-50 start-50 translate-middle"
                  v-if="!editData.image_url"
                >
                  Escolha a imagem
                </div>
              </label>

              <div class="text-danger" v-if="errors.hasOwnProperty('image')">
                {{ errors.image[0] }}
              </div>
              <input
                id="input-image-edit"
                class="d-none"
                type="file"
                name="input-image-edit"
                accept="image/x-png, image/jpeg, image/jpg"
                value=""
                @change="openImageEdit"
              />
            </div>

            <div class="mb-3">
              <label for="sort_order-edit" class="form-label">
                Ordem de classificação
              </label>
              <input
                type="number"
                class="form-control"
                v-model="editData.sort_order"
                step="0.1"
                min="0"
                id="sort_order-edit"
                :class="{ 'is-invalid': errors.hasOwnProperty('sort_order') }"
              />
              <div
                class="invalid-feedback"
                v-if="errors.hasOwnProperty('sort_order')"
              >
                {{ errors.sort_order[0] }}
              </div>
            </div>

            <div class="mb-3">
              <label for="link-edit" class="form-label">Link</label>
              <input
                type="text"
                class="form-control"
                v-model="editData.link"
                id="link-edit"
                :class="{ 'is-invalid': errors.hasOwnProperty('link') }"
                name="link"
              />
              <div
                class="invalid-feedback"
                v-if="errors.hasOwnProperty('link')"
              >
                {{ errors.link[0] }}
              </div>
            </div>
            <div class="mb-3" v-if="editData.link">
              <label for="edit-target" class="form-label"
                >Link alvo</label
              >
              <select
                class="form-select"
                :class="{ 'is-invalid': errors.hasOwnProperty('target') }"
                name="target"
                v-model="editData.target"
                id="edit-target"
              >
                <option value="_self">_self</option>
                <option value="_blank">_blank</option>
              </select>
              <div
                class="invalid-feedback"
                v-if="errors.hasOwnProperty('target')"
              >
                {{ errors.target[0] }}
              </div>
            </div>
          </div>
          <div class="modal-footer border-0">
            <button
              type="submit"
              class="btn btn-primary px-4"
              :disabled="loading"
            >
              Salve
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import FileUpload from "primevue/fileupload";

export default {
  components: { FileUpload },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [
          "heading",
          "|",
          "bold",
          "italic",
          "link",
          "bulletedList",
          "numberedList",
          "blockQuote",
        ],
      },
      banners: [],
      pageOfItems: [],
      search: "",
      data: {
        image: "",
        link: "",
        target: "_self",
        sort_order: 1,
      },
      imageUrl: "",
      editData: {
        image_url: "",
        image_path: "",
        image: "",
        link: "",
        target: "",
        sort_order: 1,
      },
      errors: {},
      loading: false,
      loadingData: true,
    };
  },
  mounted() {},
  methods: {
    fetchBanners() {
      topbar.show();
      axios
        .get("/admin/slides/all")
        .then((response) => (this.banners = response.data.data))
        .catch(({ response }) => {
          Swal.fire(`Error ${response.status}`, response.statusText, "error");
        })
        .finally(() => {
          topbar.hide();
          this.loadingData = false;
        });
    },

    refreshTable() {
      this.fetchBanners();
    },

    deleteBanner(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to reverse this action!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        focusCancel: true,
        showClass: {
          popup: "",
          icon: "",
        },
        hideClass: {
          popup: "",
        },
      }).then((result) => {
        if (result.value) {
          topbar.show();
          axios
            .delete(`/admin/slides/${id}`)
            .then((response) => {
              if (response.status == 200) {
                this.fetchBanners();
                this.$toast.success("Banner deleted");
              }
            })
            .catch(({ response }) => {
              Swal.fire(
                "Error " + response.status,
                response.statusText,
                "error"
              );
            })
            .then(() => {
              topbar.hide();
            });
        }
      });
    },
    storeBanner() {
      this.errors = {};
      topbar.show();
      this.loading = true;
      const formData = new FormData();
      formData.append("image", this.data.image);
      formData.append("sort_order", this.data.sort_order);
      formData.append("target", this.data.target);
      formData.append("link", this.data.link || "");

      const config = { headers: { "Content-Type": "multipart/form-data" } };
      axios
        .post("/admin/slides", formData, config)
        .then((response) => {
          this.fetchBanners();
          this.resetForm();
          this.$toast.success("Banner has been added");
        })
        .catch(({ response }) => {
          if (response.status === 422 || response.status === 429) {
            this.errors = response.data.errors;
          } else {
            Swal.fire(`Error ${response.status}`, response.statusText, "error");
          }
        })
        .then(() => {
          topbar.hide();
          this.loading = false;
        });
    },

    updateBanner(id) {
      this.errors = {};
      topbar.show();
      this.loading = true;
      const formData = new FormData();
      formData.append("image", this.editData.image);
      formData.append("sort_order", this.editData.sort_order);
      formData.append("target", this.editData.target);
      formData.append("link", this.editData.link || "");
      formData.append("_method", "PUT");

      const config = { headers: { "Content-Type": "multipart/form-data" } };
      axios
        .post(`/admin/slides/${id}`, formData, config)
        .then((response) => {
          this.fetchBanners();
          this.$toast.success("Banner updated");
          let updatedItem = response.data.data;
          this.openEditModal(updatedItem);
        })
        .catch(({ response }) => {
          if (response.status === 422 || response.status === 429) {
            this.errors = response.data.errors;
          } else {
            Swal.fire(`Error ${response.status}`, response.statusText, "error");
          }
        })
        .then(() => {
          topbar.hide();
          this.loading = false;
        });
    },
    openEditModal(banner) {
      this.editData.id = banner.id;
      this.editData.link = banner.link || "";
      this.editData.image = "";
      this.editData.sort_order = banner.sort_order;
      this.editData.target = banner.target;
      this.editData.image_url = banner.image_url;
      this.editData.image_path = banner.image_path;
    },

    updateStatus(id) {
      topbar.show();
      axios
        .put(`/admin/slides/status/${id}`)
        .then((response) => {
          this.fetchBanners();
          this.$toast.success("Banner updated");
        })
        .catch(({ response }) => {
          Swal.fire(`Error ${response.status}`, response.statusText, "error");
        })
        .finally(() => topbar.hide());
    },
    restoreFormControl() {
      this.errors = {};
    },
    openImage(event) {
      this.data.image = event.target.files[0] || this.data.image;
      this.imageUrl = URL.createObjectURL(this.data.image);
    },
    openImageEdit(event) {
      this.editData.image = event.target.files[0] || this.editData.image;
      this.editData.image_url = URL.createObjectURL(this.editData.image);
    },

    resetForm() {
      this.data.link = "";
      this.data.image = "";
      this.data.sort_order = 1;
      this.imageUrl = "";
    },
  },

  created: function () {
    this.fetchBanners();
  },
  computed: {
    bannerList() {
      return this.banners;
    },
  },
};
</script>