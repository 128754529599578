<template>
  <div class="row mb-3" v-if="cartItems.length > 0">
    <div class="text-black fw-bold h4 px-3">Seu pedido</div>
    <div
      class="col-md-12 d-flex align-items-stretch mb-0 mb-md-2 p-0 px-md-3"
      v-for="cartItem in cartItems"
      :key="cartItem.slug"
    >
      <div class="card card-item rounded-0 w-100">
        <div class="d-flex align-items-center">
          <div class="flex-shrink-0">
            <a :href="cartItem.url">
              <div class="item-image-wrapper">
                <picture>
                  <source type="image/jpg" :data-srcset="cartItem.image_url" />
                  <img
                    :alt="cartItem.name"
                    :data-src="cartItem.image_url"
                    aria-hidden="true"
                    class="item-image lazy"
                  />
                </picture>
              </div>
            </a>
          </div>
          <div class="flex-grow-1 card-body">
            <a
              :href="cartItem.url"
              class="
                text-black text-decoration-none
                fw-bold
                text-break
                item-name
              "
            >
              {{ cartItem.name }}

              <Icon
                icon="leaf"
                styleName="me-1"
                v-if="cartItem.is_vegan"
              ></Icon>
              <Icon
                icon="leaf_right"
                styleName="me-1"
                v-if="cartItem.is_vegetarian"
              ></Icon>
              <Icon
                icon="gluten_free"
                styleName="me-1"
                v-if="cartItem.is_gluten_free"
              ></Icon>
              <Icon
                icon="chili"
                styleName="me-1"
                v-if="cartItem.is_spicy"
              ></Icon>
              <Icon
                icon="leaves"
                styleName="me-1"
                v-if="cartItem.is_low_carb"
              ></Icon>
              <Icon
                icon="sugar_free"
                styleName="me-1"
                v-if="cartItem.is_sugar_free"
              ></Icon>
              <Icon
                icon="lactose_free"
                styleName="me-1"
                v-if="cartItem.is_lactose_free"
              ></Icon>
            </a>

            <div class="text-muted text-break">
              {{ cartItem.category_name }}
            </div>
            <div class="text-muted text-break" style="white-space: pre-wrap">
              {{ cartItem.comments }}
            </div>
            <div class="text-black align-items-center mb-2">
              {{ cartItem.view_price }} x{{ cartItem.quantity }} =
              <span class="fw-bold">{{ price(cartItem) }}</span>
              <DiscountBadge
                styleName="ms-1"
                v-if="cartItem.has_discount"
              ></DiscountBadge>
            </div>
            <div>
              <button
                type="button"
                class="btn btn-link text-danger text-decoration-none p-0"
                v-on:click="removeItem(cartItem)"
              >
               <i class="uicon ic_rr_trash"></i>
              </button>
              <button
                type="button"
                class="btn btn-link text-info text-decoration-none ms-4 p-0"
                data-bs-toggle="modal"
                :data-bs-target="`#Modal${cartItem.slug}`"
              >
                Editar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        :id="`Modal${cartItem.slug}`"
        tabindex="-1"
        :aria-labelledby="`${cartItem.slug}ModalLabel`"
        aria-hidden="true"
      >
        <div
          class="
            modal-dialog
            modal-dialog-centered
            modal-dialog-scrollable
            modal-fullscreen-md-down
          "
          style="max-width: 560px"
        >
          <div class="modal-content modal-rounded">
            <div class="modal-header py-3 border-0">
              <button
                type="button"
                class="btn-close m-0 me-auto p-1"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref="itemModalCloseButton"
              ></button>
            </div>

            <div class="modal-body">
              <div>
                <a
                  :href="cartItem.url"
                  class="
                    fs-3
                    text-decoration-none text-black
                    fw-bold
                    text-black text-break
                    item-name
                  "
                  :id="`${cartItem.slug}ModalLabel`"
                >
                  {{ cartItem.name }}
                </a>
              </div>
              <div class="text-muted small mb-3">
                {{ cartItem.category_name }}
              </div>

              <div class="mb-3">
                <property-badge-component
                  :item="cartItem"
                ></property-badge-component>
              </div>
              <div class="modal-item-image-wrapper mb-3">
                <img
                  :src="cartItem.image_url"
                  :alt="cartItem.name"
                  class="w-100 h-100 object-fit-cover rounded-main"
                />
              </div>

              <div class="mb-3" v-html="cartItem.des"></div>
              <textarea
                class="form-control"
                placeholder="Algum comentário?"
                rows="3"
                v-model="cartItem.comments"
                :maxlength="maxCharecters"
                @input="updateComments(cartItem)"
              ></textarea>
              <span class="text-muted float-end">
                {{ cartItem.comments.length }}/{{ maxCharecters }}
              </span>
            </div>
            <div class="modal-footer">
              <div
                class="
                  d-flex
                  justify-content-center
                  align-items-center
                  user-select-none
                "
              >
                <minus-button-component
                  @click="decrement(cartItem)"
                ></minus-button-component>
                <div
                  class="
                    px-4
                    mx-3
                    py-2
                    flex-fill
                    text-center
                    rounded-2
                    my-0
                    bg-light
                  "
                >
                  {{ cartItem.quantity }}
                </div>
                <plus-button-component
                  @click="increment(cartItem)"
                ></plus-button-component>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <a
    href="/order"
    class="
      bottom-0
      end-0
      position-fixed
      justify-content-center
      align-items-center
      w-100
      btn-lg btn btn-primary
      rounded-0
      d-block d-sm-none
    "
    v-if="cartItems.length > 0"
  >
    Confira - {{ cartTotal }}
  </a>

  <a
    v-if="cartItems.length > 0"
    href="/order"
    class="btn btn-lg btn-primary px-4 float-end d-none d-sm-block"
    >Confira - {{ cartTotal }} <i class="uicon ic_rr_arrow_right ms-2"></i>
  </a>

  <div v-if="cartItems.length == 0">
    <empty-cart-component></empty-cart-component>
  </div>
</template>
<script>
import EmptyCartComponent from "@/components/EmptyCartComponent";
import { Cart } from "@/services/cart";
import { blr_money_format } from "@/services/utils";
import PlusButtonComponent from "./Shared/PlusButtonComponent.vue";
import MinusButtonComponent from "./Shared/MinusButtonComponent.vue";

export default {
  components: { EmptyCartComponent, PlusButtonComponent, MinusButtonComponent },
  data() {
    return {
      cartItems: [],
      maxCharecters: 155,
    };
  },
  methods: {
    getCartItems() {
      this.cartItems = Cart.items();
      this.$store.state.cartTotal = Cart.total();
      this.$store.state.cartTotalItems = Cart.totalItems();
    },
    removeItem(item) {
      topbar.show();
      Cart.remove(item);
      this.getCartItems();
      this.$store.state.cartTotal = Cart.total();
      this.$store.state.cartTotalItems = Cart.totalItems();
      topbar.hide();
    },
    price(cartItem) {
      return blr_money_format(
        parseFloat(cartItem.base_price) * parseInt(cartItem.quantity)
      );
    },
    increment(cartItem) {
      Cart.increment(cartItem);
      this.getCartItems();
    },
    decrement(cartItem) {
      Cart.decrement(cartItem);
      this.getCartItems();
    },
    updateComments(cartItem) {
      Cart.updateComments(cartItem);
      this.getCartItems();
    },
  },
  created: function () {
    this.getCartItems();
  },
  computed: {
    cartTotal() {
      return blr_money_format(this.$store.state.cartTotal);
    },
  },
};
</script>