<template>
  <div class="sticky-top sticky-offset" style="top: 54px">
    <div class="row mb-3 p-0">
      <div class="col-md-6 px-md-3 p-0">
        <div class="dropdown">
          <button
            class="
              btn 
              w-100
              text-start
              bg-light
              rounded-0
              d-flex
              align-items-center
              drop-down-menu-btn
            "
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Categorias <Icon icon="br_angle_small_down" styleName="ms-auto"></Icon>
          </button>
          <ul
            class="
              dropdown-menu
              scrollable-dropdown
              w-100
              rounded-0
              p-0
            "
            aria-labelledby="dropdownMenuButton"
            style="max-height: 500px"
          >
            <li v-for="category in menuList" :key="category.slug">
              <a
                class="dropdown-item border-bottom py-2"
                :href="`#${category.slug}`"
                v-if="category.items.length > 0"
              >
                {{ category.name }}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-6 px-md-3 p-0">
        <div class="position-relative w-100 float-end">
          <input
            type="text"
            class="form-control rounded-0"
            name="search"
            v-model="search"
            id="search"
            autocomplete="off"
            placeholder="Procurar..."
            aria-label="Procurar..."
            style="padding-left: 2rem !important"
          />
          <div class="position-absolute top-50 start-0 translate-middle-y p-2">
            <i class="uicon ic_rr_search text-muted"></i>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="text-center" v-if="loading && menu.length === 0">
    <div
      class="spinner-border text-primary"
      style="width: 3rem; height: 3rem"
      role="status"
    >
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <div class="text-center py-5" v-if="showImage">
    <img src="/images/diet.png" height="250" />
  </div>
  <div>
    <div class="row mb-3" v-if="discountItems.length > 0">
      <div class="text-black fw-bold h4 px-3">Destaques</div>
      <div
        class="
          col-md-6 col-sm-12
          d-flex
          align-items-stretch
          mb-0 mb-md-3
          p-0
          px-md-3
        "
        v-for="discountItem in discountItems"
        :key="discountItem.slug"
      >
        <div
          class="
            card
            position-relative
            card-item
            rounded-0
            w-100
            cursor-pointer
          "
          data-bs-toggle="modal"
          data-bs-target="#itemModal"
          @click="openModal(discountItem)"
        >
          <item-component :item="discountItem" />
        </div>
      </div>
    </div>
  </div>

  <div>
    <div class="row mb-3" v-if="popularItems.length > 0">
      <div class="text-black fw-bold h4 px-3">Populares</div>
      <p class="text-muted mb-3 px-3">
        Os itens e pratos mais pedidos nesta loja
      </p>
      <div
        class="
          col-md-6 col-sm-12
          d-flex
          align-items-stretch
          mb-0 mb-md-3
          p-0
          px-md-3
        "
        v-for="popularItem in popularItems"
        :key="popularItem.slug"
      >
        <div
          class="
            card
            position-relative
            card-item
            rounded-0
            w-100
            cursor-pointer
          "
          data-bs-toggle="modal"
          data-bs-target="#itemModal"
          @click="openModal(popularItem)"
        >
          <item-component :item="popularItem" />
        </div>
      </div>
    </div>
  </div>

  <div>
    <div class="row mb-3" v-if="newItems.length > 0">
      <div class="text-black fw-bold h4 px-3">Novos</div>
      <div
        class="
          col-md-6 col-sm-12
          d-flex
          align-items-stretch
          mb-0 mb-md-3
          p-0
          px-md-3
        "
        v-for="newItem in newItems"
        :key="newItem.slug"
      >
        <div
          class="
            card
            position-relative
            card-item
            rounded-0
            w-100
            cursor-pointer
          "
          data-bs-toggle="modal"
          data-bs-target="#itemModal"
          @click="openModal(newItem)"
        >
          <item-component :item="newItem"/>
        </div>
      </div>
    </div>
  </div>

  <div v-for="category in menuList" :key="category.slug">
    <div class="row mb-3" v-if="category.items.length > 0">
      <div class="text-black fw-bold h4 px-3 mb-3" :id="category.slug">
        {{ category.name }}
      </div>
      <div
        class="
          col-md-6 col-sm-12
          d-flex
          align-items-stretch
          mb-0 mb-md-3
          p-0
          px-md-3
        "
        v-for="item in category.items"
        :key="item.slug"
      >
        <div
          class="
            card
            position-relative
            card-item
            rounded-0
            w-100
            cursor-pointer
          "
          data-bs-toggle="modal"
          data-bs-target="#itemModal"
          @click="openModal(item)"
        >
          <item-component :item="item"/>
        </div>
      </div>
    </div>
  </div>
  <item-modal-component :item="selectedItem"></item-modal-component>
</template>

<script>
import ItemComponent from "./ItemComponent.vue";
import ItemModalComponent from "./ItemModalComponent";
import { Menu } from "@/services/menu";
import { delay, lazyLoadImages } from "@/services/utils";

export default {
  components: { ItemComponent, ItemModalComponent },
  data() {
    return {
      menu: Menu.get(),
      search: "",
      loading: true,
      selectedItem: "",
    };
  },
  mounted() {},
  methods: {
    fetchMenu() {
      axios
        .get("/api/v1/categories/items")
        .then((response) => {
          this.menu = response.data.data;
          Menu.set(this.menu);
          delay(1).then(() => {
            lazyLoadImages();
          });
        })
        .finally(() => (this.loading = false));
    },
    openModal(item) {
      this.selectedItem = item;
    },
  },
  created: function () {
    this.fetchMenu();
  },
  computed: {
    menuList() {
      const search = this.search.toLowerCase();
      if (!search) return this.menu;
      return this.menu.map((category) => {
        return {
          ...category,
          items: category.items.filter((item) => {
            return item.search_name.toLowerCase().includes(search);
          }),
        };
      });
    },
    showImage() {
      const search = this.search.toLowerCase();
      if (!search) return this.menu.length == 0;
      let _items = [];
      this.menuList.forEach((category) => {
        _items = _items.concat(category.items);
      });
      return _items.length == 0;
    },

    popularItems() {
      const search = this.search.toLowerCase();
      if (search) return [];
      let _items = [];
      this.menu.forEach((category) => {
        category.items.forEach((item) => {
          if (item.is_popular) {
            _items.push(item);
          }
        });
      });
      return _items;
    },
    discountItems() {
      const search = this.search.toLowerCase();
      if (search) return [];
      let _items = [];
      this.menu.forEach((category) => {
        category.items.forEach((item) => {
          if (item.has_discount) {
            _items.push(item);
          }
        });
      });
      return _items;
    },
    newItems() {
      const search = this.search.toLowerCase();
      if (search) return [];
      let _items = [];
      this.menu.forEach((category) => {
        category.items.forEach((item) => {
          if (item.is_new) {
            _items.push(item);
          }
        });
      });
      return _items;
    },
  },
  watch: {
    search: function () {
      delay(1).then(() => {
        lazyLoadImages();
      });
    },
  },
};
</script>