<template>
  <form @submit.prevent="sendMessage">
    <div class="row">
      <div class="col-md-6 mb-3">
        <input
          type="text"
          class="form-control"
          :class="{
            'is-invalid': errors.hasOwnProperty('name'),
          }"
          placeholder="Nome*"
          aria-label="Nome*"
          v-model="data.name"
          name="name"
          autocomplete="name"
        />
        <div class="invalid-feedback" v-if="errors.hasOwnProperty('name')">
          {{ errors.name[0] }}
        </div>
      </div>
      <div class="col-md-6 mb-3">
        <input
          type="text"
          class="form-control"
          :class="{
            'is-invalid': errors.hasOwnProperty('email'),
          }"
          placeholder="Email*"
          aria-label="Email*"
          v-model="data.email"
          name="email"
          autocomplete="email"
        />
        <div class="invalid-feedback" v-if="errors.hasOwnProperty('email')">
          {{ errors.email[0] }}
        </div>
      </div>

      <div class="col-md-12 mb-3">
        <textarea
          class="form-control"
          :class="{
            'is-invalid': errors.hasOwnProperty('message'),
          }"
          placeholder="Mensagem*"
          aria-label="Mensagem*"
          rows="5"
          v-model="data.message"
        ></textarea>
        <div class="invalid-feedback" v-if="errors.hasOwnProperty('message')">
          {{ errors.message[0] }}
        </div>
      </div>
    </div>

    <div class="text-center">
      <button
        type="submit"
        class="btn btn-danger px-4"
        :disabled="loading"
      >
        Enviar Mensagem
      </button>
    </div>
  </form>
</template>

<script>
export default {
  data() {
    return {
      data: {
        name: "",
        email: "",
        message: "",
      },
      errors: {},
      loading: false,
    };
  },
  mounted() {},
  methods: {
    sendMessage() {
      this.errors = {};
      topbar.show();
      this.loading = true;
      axios
        .post("/contact/message", this.data)
        .then((response) => {
          if (response.status === 200) {
            Swal.fire(
              "Obrigado",
              "Sua mensagem foi enviada. Entraremos em contato com você o mais breve possível!",
              "success"
            );
            this.data.name = "";
            this.data.email = "";
            this.data.message = "";
          }
        })
        .catch(({ response }) => {
          if (response.status === 422 || response.status === 429) {
            this.errors = response.data.errors;
          } else {
            Swal.fire(
              "",
              "Ocorreu um erro ao enviar a mensagem. Por favor, tente novamente mais tarde",
              "warning"
            );
          }
        })
        .then(() => {
          topbar.hide();
          this.loading = false;
        });
    },
  },
  created: function () {},
  computed: {},
};
</script>