<template>
  <div class="d-flex mb-3 justify-content-center">
    <div class="h3">Cupons</div>
    <div class="ms-auto">
      <button
        class="btn btn-primary px-4 ms-2"
        data-bs-toggle="modal"
        data-bs-target="#couponModal"
      >
        <i class="uicon ic_rr_plus"></i> Adicionar Cupom
      </button>
    </div>
  </div>

  <div class="card shadow-sm">
    <div class="card-body">
      <div class="position-relative w-auto mb-3">
        <input
          type="text"
          class="form-control w-auto"
          name="search"
          v-model="search"
          id="search"
          autocomplete="off"
          placeholder="Procurar..."
          style="padding-left: 2rem !important"
        />
        <div class="position-absolute top-50 start-0 translate-middle-y p-2">
          <i class="uicon ic_rr_search text-muted"></i>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
            <tr>
              <th class="text-muted small fw-normal">Cupom</th>
              <th class="text-muted small fw-normal">Percentagem</th>
              <th class="text-muted small fw-normal">Descrição</th>
              <th class="text-muted small fw-normal">Tipo</th>
              <th class="text-muted small fw-normal">Status</th>
              <th class="text-muted small fw-normal"></th>
            </tr>
          </thead>
          <tbody class="border-top-0">
            <tr v-for="coupon in couponList" :key="coupon.id">
              <td class="align-middle">{{ coupon.code }}</td>
              <td class="align-middle">{{ coupon.percentage }}%</td>
              <td class="align-middle">{{ coupon.des }}</td>
              <td class="align-middle">{{ coupon.type }}</td>
              <td class="align-middle">
                <div class="form-check form-switch">
                  <input
                    class="form-check-input cursor-pointer"
                    type="checkbox"
                    id="flexSwitchCheckChecked"
                    :checked="coupon.active"
                    v-on:change="updateStatus(coupon.id)"
                  />
                  <label class="form-check-label" for="flexSwitchCheckChecked">
                    {{ coupon.status }}
                  </label>
                </div>
              </td>
              <td class="align-middle">
                <button
                  class="btn btn-info btn-xs me-2"
                  data-bs-toggle="modal"
                  data-bs-target="#editCouponModal"
                  v-on:click="openEditModal(coupon)"
                >
                  Editar
                </button>

                <button
                  class="btn btn-danger btn-xs"
                  v-on:click="deleteCoupon(coupon.id)"
                >
                  Excluir
                </button>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="text-center" v-if="couponList.length == 0">
          <h6>Sem dados disponíveis na tabela</h6>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Create -->
  <div
    class="modal fade"
    id="couponModal"
    tabindex="-1"
    aria-labelledby="couponModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content rounded-3">
        <div class="modal-header border-0">
          <h5 class="modal-title" id="couponModalLabel">Adicionar Cupom</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            v-on:click="restorFormControl"
          ></button>
        </div>
        <form @submit.prevent="storeCoupon">
          <div class="modal-body">
            <div class="mb-3">
              <label for="code" class="form-label fw-bold">
                Códigos de cupom
              </label>
              <input
                type="text"
                class="form-control"
                v-model="data.code"
                id="code"
                :class="{ 'is-invalid': errors.hasOwnProperty('code') }"
                name="code"
              />
              <div
                class="invalid-feedback"
                v-if="errors.hasOwnProperty('code')"
              >
                {{ errors.code[0] }}
              </div>
              <div class="form-text">Max 8 caracteres</div>
            </div>

            <div class="mb-3">
              <label for="delivery" class="form-label fw-bold">Tipo*</label>
              <select
                id="delivery"
                v-model="data.delivery"
                class="form-select rounded-main cursor-pointer"
                :class="{ 'is-invalid': errors.hasOwnProperty('delivery') }"
                aria-label="Tipo*"
              >
                <option v-bind:value="true" selected>Para Entrega</option>
                <option v-bind:value="false">Para Retirada</option>
              </select>
              <div
                class="invalid-feedback"
                v-if="errors.hasOwnProperty('delivery')"
              >
                {{ errors.delivery[0] }}
              </div>
            </div>
            <div class="mb-3">
              <label for="percentage" class="form-label fw-bold"
                >% Percentagem</label
              >
              <input
                type="number"
                min="0"
                step="0.1"
                class="form-control"
                v-model="data.percentage"
                id="percentage"
                :class="{ 'is-invalid': errors.hasOwnProperty('percentage') }"
              />
              <div
                class="invalid-feedback"
                v-if="errors.hasOwnProperty('percentage')"
              >
                {{ errors.percentage[0] }}
              </div>
            </div>

            <div class="mb-3">
              <label for="des" class="form-label fw-bold">Descrição</label>
              <textarea
                class="form-control"
                v-model="data.des"
                id="des"
                :class="{ 'is-invalid': errors.hasOwnProperty('des') }"
              ></textarea>
              <div class="invalid-feedback" v-if="errors.hasOwnProperty('des')">
                {{ errors.des[0] }}
              </div>
              <div class="form-text">Max 190 caracteres</div>
            </div>
          </div>
          <div class="modal-footer border-0">
            <button
              type="submit"
              class="btn btn-primary px-4"
              :disabled="loading"
            >
              Salve
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- Modal Update -->
  <div
    class="modal fade"
    id="editCouponModal"
    tabindex="-1"
    aria-labelledby="editCouponModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content rounded-3">
        <div class="modal-header border-0">
          <h5 class="modal-title" id="editCouponModalLabel">Editar Cupom</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            v-on:click="restorFormControl"
          ></button>
        </div>
        <form @submit.prevent="updateCoupon(editData.id)">
          <div class="modal-body">
            <div class="mb-3">
              <label for="code-edit" class="form-label fw-bold">
                Códigos de cupom
              </label>
              <input
                type="text"
                class="form-control"
                v-model="editData.code"
                id="code-edit"
                :class="{ 'is-invalid': errors.hasOwnProperty('code') }"
              />
              <div
                class="invalid-feedback"
                v-if="errors.hasOwnProperty('code')"
              >
                {{ errors.code[0] }}
              </div>
              <div class="form-text">Max 8 caracteres</div>
            </div>

            <div class="mb-3">
              <label for="delivery-edit" class="form-label fw-bold"
                >Tipo*</label
              >
              <select
                id="delivery-edit"
                v-model="editData.delivery"
                class="form-select rounded-main cursor-pointer"
                :class="{ 'is-invalid': errors.hasOwnProperty('delivery') }"
                aria-label="Tipo*"
              >
                <option v-bind:value="true" selected>Para Entrega</option>
                <option v-bind:value="false">Para Retirada</option>
              </select>
              <div
                class="invalid-feedback"
                v-if="errors.hasOwnProperty('delivery')"
              >
                {{ errors.delivery[0] }}
              </div>
            </div>
            <div class="mb-3">
              <label for="percentage-edit" class="form-label fw-bold"
                >% Percentagem</label
              >
              <input
                type="number"
                min="0"
                step="0.1"
                class="form-control"
                v-model="editData.percentage"
                id="percentage-edit"
                :class="{ 'is-invalid': errors.hasOwnProperty('percentage') }"
              />
              <div
                class="invalid-feedback"
                v-if="errors.hasOwnProperty('percentage')"
              >
                {{ errors.percentage[0] }}
              </div>
            </div>

            <div class="mb-3">
              <label for="des-edit" class="form-label fw-bold">Descrição</label>
              <textarea
                class="form-control"
                v-model="editData.des"
                id="des-edit"
                :class="{ 'is-invalid': errors.hasOwnProperty('des') }"
              ></textarea>

              <div class="invalid-feedback" v-if="errors.hasOwnProperty('des')">
                {{ errors.des[0] }}
              </div>
              <div class="form-text">Max 190 caracteres</div>
            </div>
          </div>
          <div class="modal-footer border-0">
            <button
              type="submit"
              class="btn btn-primary px-4"
              :disabled="loading"
            >
              Salve
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      coupons: [],
      pageOfItems: [],
      search: "",
      data: {
        name: "",
        percentage: 0,
        des: "",
        delivery: "",
      },

      editData: {
        id: "",
        name: "",
        percentage: 0,
        des: "",
        delivery: "",
        _method: "PUT",
      },
      errors: {},
      loading: false,
    };
  },
  mounted() {},
  methods: {
    fetchCoupons() {
      topbar.show();
      axios
        .get("/admin/coupons/all")
        .then((response) => {
          this.coupons = response.data.data;
        })
        .catch(({ response }) => {
          Swal.fire(`Error ${response.status}`, response.statusText, "error");
        })
        .then(() => {
          topbar.hide();
        });
    },
    refreshTable() {
      this.fetchCoupons();
    },

    deleteCoupon(id) {
      Swal.fire({
        title: "Você tem certeza?",
        text: "Você não poderá reverter isso!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Cancelar",
        focusCancel: true,
        showClass: {
          popup: "",
          icon: "",
        },
        hideClass: {
          popup: "",
        },
      }).then((result) => {
        if (result.value) {
          topbar.show();
          axios
            .delete("/admin/coupons/" + id)
            .then((response) => {
              if (response.status == 200) {
                this.fetchCoupons();
                this.$toast.success("Cupom Excluída");
              }
            })
            .catch(({ response }) => {
              Swal.fire(
                `Error ${response.status}`,
                response.statusText,
                "error"
              );
            })
            .then(() => {
              topbar.hide();
            });
        }
      });
    },
    storeCoupon() {
      this.errors = {};
      topbar.show();
      this.loading = true;

      axios
        .post("/admin/coupons", this.data)
        .then((response) => {
          this.fetchCoupons();
          this.data.code = "";
          this.data.percentage = 0;
          this.data.des = "";
          this.$toast.success("Cupom Adicionada");
        })
        .catch(({ response }) => {
          if (response.status === 422 || response.status === 429) {
            this.errors = response.data.errors;
          } else {
            Swal.fire(`Error ${response.status}`, response.statusText, "error");
          }
        })
        .then(() => {
          topbar.hide();
          this.loading = false;
        });
    },

    updateCoupon(id) {
      this.errors = {};
      topbar.show();
      this.loading = true;

      axios
        .post("/admin/coupons/" + id, this.editData)
        .then((response) => {
          this.fetchCoupons();
          this.$toast.success("Cupom atualizada");
        })
        .catch(({ response }) => {
          if (response.status === 422 || response.status === 429) {
            this.errors = response.data.errors;
          } else {
            Swal.fire(`Error ${response.status}`, response.statusText, "error");
          }
        })
        .then(() => {
          topbar.hide();
          this.loading = false;
        });
    },
    openEditModal(coupon) {
      this.editData.id = coupon.id;
      this.editData.code = coupon.code;
      this.editData.percentage = coupon.percentage;
      this.editData.des = coupon.des;
      this.editData.delivery = coupon.is_delivery;
    },

    updateStatus(id) {
      topbar.show();
      axios
        .put(`/admin/coupons/status/${id}`)
        .then((response) => {
          this.fetchCoupons();
          this.$toast.success("Cupom atualizada");
        })
        .catch(({ response }) => {
          Swal.fire(`Error ${response.status}`, response.statusText, "error");
        })
        .then(() => {
          topbar.hide();
        });
    },
    restorFormControl() {
      this.errors = {};
    },
  },

  created: function () {
    this.fetchCoupons();
  },
  computed: {
    couponList() {
      const search = this.search.toLowerCase();
      if (!search) return this.coupons;
      return this.coupons.filter((coupon) => {
        return (
          coupon.code.toLowerCase().includes(search) ||
          coupon.percentage.toString().toLowerCase().includes(search) ||
          coupon.des.toLowerCase().includes(search)
        );
      });
    },
  },
};
</script>          